import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PurpleTitle from '../../Common/PurpleTitle';
import { phone } from '../../../constants';
import './Brief.scss';
import { NavLink } from 'react-router-dom';
import Helmet from 'react-helmet';
import { promoAPI } from '../../../api';
import Alert from '../../Common/Alert';
import { useAppContext } from '../../../AppContext';
import WithTransition from '../../HOCs/WithTransition';
import ym from 'react-yandex-metrika';
import ToTopBtn from '../../Common/ToTopBtn';

const otraslies = [
  { id: 1, value: 'E-commerce & Retail'},
  { id: 2, value: 'Производство и промышленность'},
  { id: 3, value: 'Недвижимость и строительство'},
  { id: 4, value: 'Другое'},
]

const services = [
  { id: 1, value: 'Интернет-магазин' },
  { id: 2, value: 'Мобильное приложение' },
  { id: 3, value: 'Web-сервис' },
  { id: 4, value: 'Личный кабинет' },
  { id: 5, value: 'Корпоративный софт' },
  { id: 6, value: 'Корпоративный сайт' },
  { id: 7, value: 'Интеграции' },
  { id: 8, value: 'Аутстаффинг' },
];

const budget = [
  { id: 1, value: 'До 500 тыс.' },
  { id: 2, value: '500 тыс. - 1 млн.' },
  { id: 3, value: '1 млн. - 3 млн.' },
  { id: 4, value: 'Более 3 млн.' }
];

const allowedTypes = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'application/msword',
  'application/zip',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

const BriefPage = () => {
  const history = useHistory();
  const [selectedOtrasli, setSelectedOtrasli] = useState(new Set());
  const [selectedServices, setSelectedServices] = useState(new Set());
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [_phone, setPhone] = useState('');
  const [task, setTask] = useState('');
  const [errors, setErrors] = useState(new Set());
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({});

  const textareaRef = useRef(null);
  const { initPage, mainRef } = useAppContext();

  useEffect(() => {
    initPage();
  }, []);

  const cleanForm = () => {
    setSelectedOtrasli(new Set());
    setSelectedServices(new Set());
    setSelectedBudget(null);
    setSelectedFiles([]);
    setName('');
    setCompanyName('');
    setEmail('');
    setPhone('');
    setErrors(new Set());
    setTask('');
    textareaRef.current.style.height = '45px';
  };

  const validate = (inputName, inputValue) => {
    switch (inputName) {
      case 'phone':
        return inputValue.trim().length > 0;
      default:
        return true;
    }
  };

  const validateBrief = () => {
    const inputs = mainRef.current.querySelectorAll('input[type="text"]');

    let newErrors = new Set(errors);
    inputs.forEach((item) => {
      validate(item.name, item.value) ? newErrors.delete(item.name) : newErrors.add(item.name);
    });

    setErrors(newErrors);
    return newErrors.size;
  };

  const sendBrief = () => {
    let errorsSize = validateBrief();

    if (!errorsSize) {
      let formData = new FormData();

      !!selectedBudget && formData.append('budget', selectedBudget);
      !!name && formData.append('name', name);
      !!companyName && formData.append('companyName', companyName);
      !!email && formData.append('email', email);
      formData.append('phone', _phone);
      !!task && formData.append('task', task);
      formData.append('type', 'brief');

      if(selectedOtrasli.size) {
        for (let item of selectedOtrasli) {
          formData.append('otrasli[]', item)
        }
      }

      if (selectedServices.size) {
        for (let item of selectedServices) {
          formData.append('services[]', item);
        }
      }

      if (selectedFiles.length) {
        for (let item of selectedFiles) {
          formData.append('files[]', item);
        }
      }
      
      for (var pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]);
      }
      
      promoAPI
        .sendAudit(formData)
        .then(() => {
          cleanForm();
          history.push('/success');
          ym('reachGoal', 'sendrequest');
        })
        .catch(() => {
          setAlertContent({
            title: '',
            isErrorAlert: true,
            text: 'Что-то пошло не так, попробуйте отправить бриф позже.',
            buttonText: 'Вернуться',
            onClose: () => setShowAlert(false)
          });
          setShowAlert(true);
        });
    }
  };
  const selectOtrasli = (otrasl) => {
    let newOtrasli = new Set(selectedOtrasli);
  
    if (newOtrasli.has(otrasl)) {
      newOtrasli.delete(otrasl);
    } else {
      newOtrasli.add(otrasl);
    }
    setSelectedOtrasli(newOtrasli);
  };

  const selectService = (service) => {
    let newServices = new Set(selectedServices);
  
    if (newServices.has(service)) {
      newServices.delete(service);
    } else {
      newServices.add(service);
    }
    setSelectedServices(newServices);
  };

  const selectBudget = (budget) => {
    setSelectedBudget(selectedBudget === budget ? null : budget);
  };

  const onInputChange = (e) => {
    let val = e.target.value;
    switch (e.target.name) {
      case 'email':
        setEmail(val);
        break;
      case 'phone':
        setPhone(val);
        break;
      case 'companyName':
        setCompanyName(val);
        break;
      case 'task':
        setTask(val);
        break;
      default:
        setName(val);
    }
  };

  const addFile = (file) => {
    if (file.type.includes('image') || allowedTypes.includes(file.type)) {
      setSelectedFiles((prev) => [...prev, file]);
    }
  };

  const deleteFile = (e) => {
    const fileID = +e.currentTarget.getAttribute('data-id');

    const newSelectedFiles = selectedFiles.filter((item) => item.lastModified !== fileID);
    setSelectedFiles(newSelectedFiles);
  };

  const adjustHeight = () => {
    textareaRef.current.style.height =
      textareaRef.current.scrollHeight > textareaRef.current.clientHeight
        ? textareaRef.current.scrollHeight + 'px'
        : '45px';
  };

  return (
    <>
      <Helmet>
        <title>Запустим продукт вместе</title>
        <meta name="description" content="Brief component" />
      </Helmet>
      {showAlert && <Alert {...alertContent} />}
      <section className={`brief`}>
        <div className="wrapper">
          <PurpleTitle title="Запустим продукт вместе" />
          <div className="brief__content">
            <p className="brief__desc">
              Мы свяжемся и обсудим детали работы над вашим продуктом:{' '}
              <a href={`tel:${phone}`} className="brief__desc-phone">
                {phone}
              </a>
            </p>
            <div className="brief__section">
              <span className="brief__section-title">Отрасль</span>
              <div className="brief__buttons">
                {otraslies.map((item) => {
                  return (
                    <button
                      key={item.id}
                      onClick={() => selectOtrasli(item.value)}
                      className={`brief__button ${
                        selectedOtrasli.has(item.value) ? 'brief__button_selected' : ''
                      }`}
                    >
                      {item.value}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="brief__section">
              <span className="brief__section-title">Продукт/услуга</span>
              <div className="brief__buttons">
                {services.map((item) => {
                  return (
                    <button
                      key={item.id}
                      onClick={() => selectService(item.value)}
                      className={`brief__button ${
                        selectedServices.has(item.value) ? 'brief__button_selected' : ''
                      }`}
                    >
                      {item.value}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="brief__section">
              <span className="brief__section-title">Бюджет</span>
              <div className="brief__buttons">
                {budget.map((item) => {
                  return (
                    <button
                      key={item.id}
                      onClick={() => selectBudget(item.value)}
                      className={`brief__button ${
                        selectedBudget === item.value ? 'brief__button_selected' : ''
                      }`}
                    >
                      {item.value}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="brief__section">
              <span className="brief__section-title">Опишите задачу</span>
              <div className="brief__input-wrapper">
                <textarea
                  placeholder="Описание"
                  ref={textareaRef}
                  onKeyUp={adjustHeight}
                  className={`brief__input brief__input_task ${
                    errors.has('task') ? 'brief__input_error' : ''
                  }`}
                  value={task}
                  name="task"
                  onChange={onInputChange}
                />
              </div>
              <div className="brief__input-wrapper brief__input-wrapper_file">
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept={`${allowedTypes.join(', ')}`}
                  className="brief__input brief__input_file"
                  onChange={(e) => addFile(e.target.files[0])}
                />
                <label htmlFor="file" className="brief__input-label">
                  Прикрепить файл
                </label>
              </div>
              <div className="brief__files">
                {selectedFiles.map((file, i) => {
                  return (
                    <div key={i} className="brief__files-file">
                      <button
                        className="brief__files-deleteBtn"
                        data-id={file.lastModified}
                        onClick={(e) => deleteFile(e)}
                      >
                        <svg data-id={file.lastModified} width="12" height="12" fill="none">
                          <path d="M1 1l10 10M11 1L1 11" stroke="#151515" strokeWidth="1.5" />
                        </svg>
                      </button>
                      <span className="brief__files-title">{file.name}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="brief__section brief__section_contacts">
              <span className="brief__section-title">Контактные данные</span>
              <div className="brief__input-wrapper">
                <input
                  type="text"
                  placeholder="Имя"
                  className={`brief__input ${errors.has('name') ? 'brief__input_error' : ''}`}
                  name="name"
                  value={name}
                  onChange={onInputChange}
                />
              </div>
              <div className="brief__input-wrapper">
                <input
                  type="text"
                  placeholder="Компания"
                  className={`brief__input ${
                    errors.has('companyName') ? 'brief__input_error' : ''
                  }`}
                  name="companyName"
                  value={companyName}
                  onChange={onInputChange}
                />
              </div>
              <div className="brief__input-wrapper">
                <input
                  type="text"
                  placeholder="E-mail"
                  className={`brief__input ${errors.has('email') ? 'brief__input_error' : ''}`}
                  name="email"
                  value={email}
                  onChange={onInputChange}
                />
              </div>
              <div className="brief__input-wrapper">
                <input
                  type="text"
                  placeholder="Телефон/Telegram"
                  className={`brief__input ${errors.has('phone') ? 'brief__input_error' : ''}`}
                  value={_phone}
                  name="phone"
                  onChange={onInputChange}
                />
              </div>
            </div>
            <button type="button" className="brief__sendBtn" onClick={sendBrief}>
              <span className='label-text'>Отправить запрос</span>
            </button>
            <p className="brief__policy">
              Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь с
              политикой конфиденциальности.
            </p>
          </div>
          <div className="footer footer_brief">
            <NavLink to="/" className="footer__logo-title">
              <div className="footer__logo-logo">
                <svg className="footer__logo-pic" width="800" height="170" viewBox="0 0 800 170" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M796.022 28.6809C794.033 22.714 790.055 18.0731 785.414 14.0952C780.11 10.1173 774.143 6.80231 768.176 4.81335C760.22 2.82439 752.265 2.1614 744.309 2.1614H611.711V131.444L526.186 2.1614H497.678L412.152 131.444C412.152 129.455 412.815 127.466 412.815 124.814V105.587C412.815 99.6204 412.152 93.6536 410.163 88.3497C408.174 82.3828 404.197 77.7419 399.556 73.764C394.252 69.7861 388.285 66.4711 382.318 64.4822C374.362 62.4932 366.406 61.8302 358.45 61.8302H269.61C257.013 61.8302 257.013 56.5263 257.013 53.8744V44.5926C257.013 41.9406 257.013 36.6367 269.61 36.6367H406.185V0.172466H269.61C261.654 -0.490521 253.035 0.835412 245.743 2.82437C239.113 4.15035 233.146 7.46529 228.505 12.1062C223.864 16.0841 219.886 20.7251 217.897 26.6919C215.908 31.9958 215.245 37.9627 215.245 43.9296V53.2114C215.245 59.1783 215.908 65.1451 217.897 70.449C219.886 76.4159 223.864 81.0568 228.505 85.0347C233.809 89.0126 239.776 92.3276 245.743 94.3166C253.698 96.3055 261.654 96.9685 269.61 96.9685H359.776C372.373 96.9685 372.373 102.272 372.373 104.924V124.151C372.373 126.803 372.373 132.107 359.776 132.107H227.179L140.991 1.49842H112.482L0.437561 169.897H48.8356L124.416 51.8854L199.997 169.897H436.02L512.926 51.8854L588.507 169.897H655.469V112.88H745.635C753.59 113.543 762.209 112.217 769.502 110.228C776.132 108.902 782.099 105.587 786.74 100.946C791.381 96.9685 795.359 92.3276 797.348 86.3607C799.337 81.0568 800 75.0899 800 69.123V46.5815C800 40.6147 798.011 34.6477 796.022 28.6809ZM756.905 45.2555V68.4601C756.905 71.112 756.905 76.4159 744.309 76.4159H654.142V38.6257H744.309C756.905 38.6257 756.905 42.6036 756.905 45.2555Z" fill="#0A0D14"/>
                </svg>
              </div>
            </NavLink>
            <p className="footer__copyright">
              &copy; {`2015–${new Date(Date.now()).getFullYear()} ASAP`}
            </p>
          </div>
        </div>
      </section>
      {/* <ToTopBtn /> */}
    </>
  );
};

export default WithTransition(BriefPage);
