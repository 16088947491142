import React, { useContext, useState } from 'react';

const AppContext = React.createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children, mainRef }) => {
  const [isOpenedMenu, setIsOpenedMenu] = useState(false);

  const toggleMenu = (open) => {
    if (open) {
      setIsOpenedMenu(true);
      mainRef.current.closest('body').classList.add('noscroll');
    } else {
      setIsOpenedMenu(false);
      mainRef.current.closest('body').classList.remove('noscroll');
    }
  };

  const initPage = () => {
    toggleMenu();
    setIsOpenedMenu(false);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 250);
  };

  return (
    <AppContext.Provider value={{ initPage, mainRef, toggleMenu, isOpenedMenu, setIsOpenedMenu }}>
      {children}
    </AppContext.Provider>
  );
};
