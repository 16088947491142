import React from "react";
import {motion} from "framer-motion";

const pageVariants = {
    in: {
        opacity: 1,
    },
    out: {
        opacity: 0,
    }
};

const pageTransition = {
    duration: 0.5,
    delay: 0.3
};

const WithTransition = Component => props => {
    return <motion.div exit="out"
                       animate="in"
                       initial="out"
                       variants={pageVariants}
                       transition={pageTransition}>
        <Component {...props} />
    </motion.div>
};

export default WithTransition;
