import React, { useState, useEffect, useRef } from 'react';
import ym from 'react-yandex-metrika';
import ReactPixel from 'react-facebook-pixel';
import ReactHtmlParser from 'react-html-parser';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

import Alert from '../../Common/Alert';
import { useAppContext } from '../../../AppContext';
import { promoAPI } from '../../../api';
import { useScroll } from '../../../hooks';
import './index.scss';

const AnalyticsStore = ({ api }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState(new Set());
  const { mainRef } = useAppContext();

  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({});

  const clearForm = () => {
    setName('');
    setPhone('');
    setErrors(new Set());
  };

  const validate = (inputName, inputValue) => {
    switch (inputName) {
      case 'phone':
        return !!inputValue.match(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){6,14}(\s*)?$/);
      default:
        return true;
    }
  };

  const validateForm = () => {
    const inputs = mainRef.current.querySelectorAll('input[type="text"]');

    let newErrors = new Set(errors);
    inputs.forEach((item) => {
      validate(item.name, item.value) ? newErrors.delete(item.name) : newErrors.add(item.name);
    });

    setErrors(newErrors);
    return newErrors.size;
  };

  const sendForm = (e) => {
    e.preventDefault();
    let errorsSize = validateForm();
    if (!errorsSize) {
      let formData = new FormData();
      !!name && formData.append('name', name);
      !!phone && formData.append('phone', phone);
      formData.append('type', 'audit');

      promoAPI
        .sendAudit(formData)
        .then(() => {
          clearForm();
          setAlertContent({
            title: 'Заявка успешно отправлена',
            text: 'Мы ознакомимся с информацией и свяжемся с Вами в ближайшее время.',
            buttonText: 'Закрыть',
            onClose: () => {
              setShowAlert(false);
            }
          });
          setShowAlert(true);
          ym('reachGoal', 'provwsti-audit');
          ReactPixel.track('SubmitApplication');
          ReactGA.event({
            category: 'appayer_sur_le_bouton',
            action: 'envoyer_linformation',
            label: 'distuter_du_projet_bloc_derreur'
          });
        })
        .catch(() => {
          setAlertContent({
            title: '',
            isErrorAlert: true,
            text: 'Что-то пошло не так, попробуйте отправить заявку позже.',
            buttonText: 'Вернуться',
            onClose: () => setShowAlert(false)
          });
          setShowAlert(true);
        });
    }
  };

  const onInputChange = (e) => {
    let val = e.target.value;
    switch (e.target.name) {
      case 'phone':
        setPhone(val);
        break;
      default:
        setName(val);
    }
  };

  const ref = useRef(null);
  useScroll(ref);

  const [data, setData] = useState([]);
  useEffect(() => {
    api.sendInfo('analitycs').then((res) => setData(res));
  }, []);

  return (
    <section className="analyticsStore" id="analytics" ref={ref}>
      {showAlert && <Alert {...alertContent} />}
      <div className="analytics__info" data-aos="fade-up">
        <div className="analytics__helper" data-aos="fade-up">
          <h2 className="analyticsStore__title">{ReactHtmlParser(data?.analitycsTitle)}</h2>
          <p className="analyticsStore__subtitle">{ReactHtmlParser(data?.analitycsDesc)}</p>
        </div>
        <div className="analyticsStore__info">
          <p className="analyticsStore__text">
            К моменту запуска сайта мы настраиваем рекламные кампании, чтобы вы получали продажи с
            выхода в электронную коммерцию
          </p>
          <a href="https://asap-traffic.ru" target="_blank" className="analyticsStore__button">
            Посмотреть предложение
          </a>
        </div>
      </div>
      <h2 className="analyticsStore__CarouselText">РЕКЛАМА РЕК</h2>
    </section>
  );
};

export default AnalyticsStore;
