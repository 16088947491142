import React, { useEffect } from 'react';
import 'swiper/swiper.scss';
import Helmet from 'react-helmet';

import ContactsSection from '../MainPage/MainPageSections/ContactsSection';
import WithTransition from '../../HOCs/WithTransition';
import WithDeviceType from '../../HOCs/WithDeviceType';
import { useAppContext } from '../../../AppContext';
import { lavaLamp } from '../../functions/functions';
import { promoDataAPI } from '../../../api';
import { projectsStore } from '../../../constants';

import Header from '../../PromotionBlocks/Header';
import Cards from '../../PromotionBlocks/Cards';
import Projects from '../../PromotionBlocks/Projects';
import Desc from '../../PromotionBlocks/Desc';
import Team from '../../PromotionBlocks/Team';
import Share from '../../PromotionBlocks/Share';
import Accordions from '../../PromotionBlocks/Accordions';
import FactsList from '../../PromotionBlocks/FactsList';
import Reviews from '../../PromotionBlocks/Reviews';
import Marketing from '../../PromotionBlocks/Marketing';
import Results from '../../PromotionBlocks/Results';
import AnalyticsStore from '../../PromotionBlocks/AnalyticsStore';

import './index.module.scss';

const PromotionStorePage = ({ isIndexPage, setIsIndexPage }) => {
  const { initPage, mainRef } = useAppContext();
  const dataAPI = new promoDataAPI('razrabotkaim.json');

  useEffect(() => {
    setIsIndexPage(true);

    lavaLamp();

    return () => {
      setIsIndexPage(false);
      mainRef.current.closest('body').classList.remove('main-page');
    };
  }, [isIndexPage]);

  useEffect(() => {
    initPage();
  }, []);

  const StoreTitle = () => (
    <h1 className="accordionSolutions__title">
      Мы выстроили <span className="purple">поэтапный процесс разработки</span>, покрывающий все
      аспекты e&#8209;commerce продукта
    </h1>
  );

  return (
    <>
      <Helmet>
        <title>ASAP. Разработка программного обеспечения и сложных сайтов</title>
        <meta
          name="description"
          content="Digital-решения для амбициозных компаний. Разработка сайтов и цифровых продуктов. Комплексный интернет-маркетинг."
        />
      </Helmet>
      <Header mainRef={mainRef} api={dataAPI} />
      <Cards api={dataAPI} purpleColor={true} />
      <Projects projects={projectsStore} style={{ background: 'none' }} />
      {/* <Accordions api={dataAPI} style={{ marginTop: '6rem' }} /> */}
      <Desc api={dataAPI} Title={StoreTitle} />
      <Team
        api={dataAPI}
        style={{ marginTop: '12.5rem' }}
        mainWorker={
          'http://asap-ag.ru//storage/projectMainPic/LSTAnxxQYXWh42l01EuoASQ1gxa4J4dn7qjjxFWb.jpg'
        }
      />
      <FactsList api={dataAPI} style={{ marginTop: '5.75rem' }} />
      <AnalyticsStore api={dataAPI} />
      <Results api={dataAPI} style={{ marginTop: '7.5rem' }} />
      <Marketing api={dataAPI} style={{ marginTop: '9.125rem' }} />
      <Reviews style={{ marginTop: '10.375rem' }}>
        <h2 className="reviews__title">Делимся отзывами о&nbsp;сотрудничестве</h2>
      </Reviews>
      <Share
        style={{ marginTop: '11.0625rem', marginBottom: '8.8125rem' }}
        title={`Расскажите ценность своего продукта и мы подберём решение электронной коммерции`}
      />
      <ContactsSection api={dataAPI} />
    </>
  );
};

export default WithTransition(WithDeviceType(PromotionStorePage));
