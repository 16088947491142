import React, { useEffect, useState } from 'react';
import ContactsSection from '../MainPage/MainPageSections/ContactsSection';
import WithDeviceType from '../../HOCs/WithDeviceType';
import Helmet from 'react-helmet';
import { useAppContext } from '../../../AppContext';
import WithTransition from '../../HOCs/WithTransition';
import '../MainPage/MainPage.scss';
import { NavLink, useHistory } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import TextSection from '../MainPage/MainPageSections/TextSection';
import SocialSection from '../MainPage/MainPageSections/SocialSection';
import BlackButton from '../../Common/BlackButton/BlackButton';
import TitleSection from '../../TitleSection/TitleSection';
import './EcommercePage.scss';
import FactsSlider from '../../FactsSlider/FactsSlider';
import ExpertizaSection from '../MainPage/MainPageSections/ExpertizaSection';
import { useDeviceType } from '../../../hooks';
import stroy from '../../../assets/images/projects/newProjects/stroy.png';
import fitness from '../../../assets/images/projects/newProjects/fitnessу.png';
import torex from '../../../assets/images/projects/newProjects/torex.png';
import vkusnoAnd from '../../../assets/images/projects/newProjects/vkusno.png';
import rvi from '../../../assets/images/projects/newProjects/rvi.png';
import yacars from '../../../assets/images/projects/newProjects/yacar.jpg';

const EcommercePage = ({ isIndexPage, setIsIndexPage }) => {

    const { initPage, mainRef } = useAppContext();
    const projectsFirst = [
        {
            "id": 149,
            "title": "Строй-С",
            "tags": [
                "Интернет-магазин",
            ],
            "types": [
                "E-commerce & Retail"
            ],
            "link": "stroi-s",
            "pic": stroy,
            "description": "Увеличили выручку интернет-магазина сети строительных гипермаркетов на 80% за счёт конверсии и среднего чека покупки.",
            "website": "https://stroy-s.org"
        },
        {
            "id": 151,
            "title": "Torex",
            "tags": [
                "Корпоративный сайт",
                "Онлайн-конфигуратор"
            ],
            "types": [
                "E-commerce & Retail"
            ],
            "link": "torex-premium",
            "pic": torex,
            "description": "Увеличили объем продаж входных дверей в салонах, внедрив онлайн-конфигуратор.",
        },
        {
            "id": 153,
            "title": "RVI",
            "tags": [
                "Интернет-магазин",
            ],
            "types": [
                "E-commerce & Retail",
                "PromTech"
            ],
            "link": "rvi",
            "pic": rvi,
            "description": "Интернет-магазин одного из лидеров систем безопасности в России и СНГ.",
            "website": "https://rvigroup.ru"
        },
        {
            "id": 154,
            "title": "Yacar",
            "tags": [
                "Web-cервис",
            ],
            "types": [
                "E-commerce & Retail",
            ],
            "link": "yacar",
            "pic": yacars,
            "description": "Автомобильный онлайн-аукцион. Интеграции с 3-мя сервисами иностранных аукционов."
        },
        {
            "id": 152,
            "title": "Вкусно и быстро",
            "tags": [
                "Мобильное приложение"
            ],
            "types": [
                "E-commerce & Retail",
                "FoodTech"
            ],
            "link": "vkusno-i-bystro",
            "pic": vkusnoAnd,
            "description": "Собственная доставка для ресторанов быстрого питания."
        },
        {
            "id": 155,
            "title": "Fitness кухня",
            "tags": [
                "Мобильное приложение",
            ],
            "types": [
                "E-commerce & Retail",
                "FoodTech"
            ],
            "link": "fitness-kuxnya",
            "pic": fitness,
            "description": "Сервис доставки здорового питания."
        },
    ]

    const { mobile } = useDeviceType();
    const history = useHistory();

    const [isProjectsPage] = useState(history.location.pathname === '/projects');

    const getImgSrc = (index, item) => {
        if (isProjectsPage) {
            return index !== 0 && (index + 1) % 3 === 0 ? item.mainPic : item.pic;
        }
        return item.pic;
    };
    useEffect(() => {
        initPage();
    }, []);

    return (
        <div className='eccomerce'>
            <Helmet>
                <title>Ecommerce & Retail</title>
                <meta
                    name="description"
                    content="Digital-решения для амбициозных компаний. Разработка сайтов и цифровых продуктов. Комплексный интернет-маркетинг."
                />

            </Helmet>
            <TitleSection textTitle='Ecommerce & Retail' textContent={`Запустили более 20 проектов в сфере электронной торговли с 2017 года.<br/>Знаем рынок готовых отраслевых решений и умеем их интегрировать.`}>
                <FactsSlider />
            </TitleSection>
            <ExpertizaSection
                titleText="Проекты"
            >
                <div className="grid-cards">
                    {projectsFirst.map((item, i) => {
                        return (
                            <NavLink
                                key={i}
                                to={{
                                    pathname: `/projects/${item.link}`,
                                    state: {
                                        link: item.link
                                    }
                                }}
                                className="projects-gallery__project"
                            >
                                <div className="projects-gallery__project-pic">
                                    {mobile ? (
                                        <div className="projects-gallery__project-pic-wrapper">
                                            <img
                                                src={getImgSrc(i, item)}
                                                alt={item.title}
                                                className="projects-gallery__project-img"
                                            />
                                        </div>
                                    ) : (
                                        <img
                                            src={getImgSrc(i, item)}
                                            alt={item.title}
                                            className="projects-gallery__project-img"
                                        />
                                    )}
                                </div>

                                <div className="projects-gallery__project-info">
                                    <span className="projects-gallery__project-title">{item.title}</span>
                                    <p className="label-text projects-gallery__text">
                                        {item.description}
                                    </p>
                                    {!mobile && (
                                        <div className="projects-gallery__project-tags">
                                            {item.types.map((tag, i) => (
                                                <span key={i} className="projects-gallery__project-type">
                                                    {tag}
                                                </span>
                                            ))}
                                            {item.tags.map((tag, i) => (
                                                <span key={i} className="projects-gallery__project-tag">
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                {mobile && (
                                    <div className="projects-gallery__project-tags">
                                        {item.types.map((tag, i) => (
                                            <span key={i} className="projects-gallery__project-type">
                                                {tag}
                                            </span>
                                        ))}
                                        {item.tags.map((tag, i) => (
                                            <span key={i} className="projects-gallery__project-tag">
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </NavLink>
                        );
                    })}
                </div>
            </ExpertizaSection>
            <TextSection>
            <div className="history-textbox">
                Интернет-магазины, мобильные приложения, онлайн-конфигураторы, web-сервисы, корпоративные сайты.
                <br/>
                Повышение выручки интернет-магазинов за счёт конверсии и среднего чека.
                <br/>
                Повышение оффлайн-продаж за счёт IT-инструментов.
            </div>
            </TextSection>
            <SocialSection
                titleText="Цитата"
                personText="Для наших клиентов мы являемся технологическими партнерами с отраслевой экспертизой, а не просто подрядчиками."
            >
                <NavLink
                    to={`/brief`}
                    onClick={() => {
                        ReactGA.event({
                            category: 'appayer_sur_le_bouton',
                            action: 'aller_sur_une_autre_page',
                            label: 'aller_sur_une_autre_page_toit_de_site'
                        });
                        ym('reachGoal', 'startproject');
                    }}
                    className=""
                >
                    <BlackButton>Запустим продукт вместе</BlackButton>
                </NavLink>
            </SocialSection>
            <ContactsSection />
        </div>
    )
}

export default WithTransition(WithDeviceType(EcommercePage))