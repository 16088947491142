import React, { useEffect, useRef } from 'react';
import { getOffset } from '../functions/functions';
import './scss/Orbit.scss';

let svgMaxWidth = 0;
let svgMinWidth = 0;

const Orbit = ({ facts, stopped, mainRef, title }) => {
  let prevDotPos = useRef(0).current;
  let orbitAnimation1, orbitAnimation2;
  const windowWidth = window.screen.width;

  switch (true) {
    case windowWidth <= 1700 && windowWidth > 1600:
      svgMaxWidth = 300;
      svgMinWidth = 190;
      break;
    case windowWidth <= 1600 && windowWidth > 1500:
      svgMaxWidth = 270;
      svgMinWidth = 170;
      break;
    case windowWidth <= 1500 && windowWidth > 1400:
      svgMaxWidth = 250;
      svgMinWidth = 150;
      break;
    case windowWidth <= 1400 && windowWidth > 1300:
      svgMaxWidth = 230;
      svgMinWidth = 130;
      break;
    case windowWidth <= 1300 && windowWidth > 1200:
      svgMaxWidth = 210;
      svgMinWidth = 110;
      break;
    case windowWidth <= 1200:
      svgMaxWidth = 200;
      svgMinWidth = 100;
      break;
    default:
      svgMaxWidth = 360;
      svgMinWidth = 190;
      break;
  }

  const animateOrbit = () => {
    if (mainRef?.current?.querySelector('.orbit__circle')) {
      const htmlFontSize = parseFloat(
        window.getComputedStyle(document.documentElement)['font-size']
      );
      const ellipseWidth = document.querySelector('.orbit__ellipse').offsetWidth;

      if (document.querySelector('#dot1')) {
        changeDotSize(document.querySelector('#dot1'), ellipseWidth, htmlFontSize);
      }

      if (document.querySelector('#dot2')) {
        changeDotSize(document.querySelector('#dot2'), ellipseWidth, htmlFontSize);
      }

      if (document.querySelector('#dot3')) {
        changeDotSize(document.querySelector('#dot3'), ellipseWidth, htmlFontSize);
      }

      orbitAnimation1 = requestAnimationFrame(animateOrbit);
    }
  };

  useEffect(() => {
    if (!stopped) {
      animateOrbit();
      orbitAnimation2 = requestAnimationFrame(animateOrbit);
    }

    return () => {
      cancelAnimationFrame(orbitAnimation1);
      cancelAnimationFrame(orbitAnimation2);
    };
  }, []);

  const changeDotSize = (el, parent, htmlFS) => {
    let nextDotPos = getOffset(el).top;
    let svgWidth = 0;

    if (el.querySelector('svg')) {
      svgWidth = parseFloat(window.getComputedStyle(el.querySelector('svg'))['width']);

      if (prevDotPos > nextDotPos) {
        svgWidth = parseFloat(window.getComputedStyle(el.querySelector('svg'))['width']) + 3;
      } else {
        svgWidth = parseFloat(window.getComputedStyle(el.querySelector('svg'))['width']) - 3;
      }

      if (svgWidth > svgMaxWidth) {
        svgWidth = svgMaxWidth;
      }

      if (svgWidth < svgMinWidth) {
        svgWidth = svgMinWidth;
      }

      el.querySelector('svg').style.width = `${svgWidth / htmlFS}rem`;
      prevDotPos = nextDotPos;
    }
  };

  return (
    <div className={`orbit__circle ${stopped ? 'orbit__circle_stopped' : ''}`} data-aos="fade-up">
      <div className="orbit__wrap">
        {title && <h3 className="orbit__title">{title}</h3>}
        <div className="orbit__ellipse">
          <div className="orbit__orbit">
            {facts.map((item, i) => {
              return (
                <div key={i} className="orbit__dot" id={`dot${i + 1}`}>
                  {!stopped ? (
                    <div className="orbit__dot-content">{item.pic}</div>
                  ) : (
                    <div
                      className="orbit__dot-content"
                      data-aos-delay={(i + 1) * 100}
                      data-aos="fade-up"
                      data-aos-offset="-300"
                    >
                      <span className="orbit__dot-num">{item.num}</span>
                      <span className="orbit__dot-text">{item.text}</span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orbit;
