import React, { useRef } from 'react';
import './scss/ContactsSection.scss';
import Footer from '../../../Common/Footer';
import WithDeviceType from '../../../HOCs/WithDeviceType';
import { NavLink } from 'react-router-dom';
import { address, email, fb, inst, phone, vk, be, youtube, telegram } from '../../../../constants';
import ym from 'react-yandex-metrika';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { useScroll } from '../../../../hooks';
import footerImg from '../../../../assets/images/footer.png'

const ContactsSection = () => {
    const ref = useRef(null);
    useScroll(ref);

    return (
        <section id="contacts" ref={ref} className="section section_contacts" data-aos="fade-up">
            <img src={footerImg} alt="" className='footer__bg' />
            <div className="wrapper wrapper_contacts">
                <div className="contacts-header">
                    <h2 className="h3">
                        Сотрудничество
                    </h2>
                    <div className="contacts__contacts">
                        <a
                            href={`mailto:${email}`}
                            onClick={() => ym('reachGoal', 'mail')}
                            className="contacts__email"
                        >
                            {email}
                        </a>
                        <p className="body-xl">Найдём лучшие решения для ваших задач</p>
                        <NavLink
                            to="/brief"
                            onClick={() => {
                                ym('reachGoal', 'startproject');
                                ReactGA.event({
                                    category: 'appayer_sur_le_bouton',
                                    action: 'aller_sur_une_autre_page',
                                    label: ' la_cave'
                                });
                            }}
                            className="contacts__brief-btn"
                        >
                           <span className='label-text'>Запустим продукт вместе</span>
                        </NavLink>
                    </div>
                </div>
                <div className="contacts-footer">
                    <a style={{visibility: 'hidden'}} href="https://wemakefab.ru/policy/privacy-policy.pdf" target='_blank' 
                        className="contacts-footer-policy label-text"
                    >
                        политика конфиденциальности
                    </a>
                    <div className="contacts-footer-right">
                        <div className="contacts-footer-address label-text">
                            <a
                                href="https://yandex.ru/maps/-/CCUE54r1XD"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {address}
                            </a>
                            <a href="tel:88452531888" className='label-text'>8 (8452) 531-888</a>
                        </div>
                        <div className="contacts-footer-footer">
                            <p className="label contacts-copy">© 2015–2024 IT-компания ASAP</p>
                            <div className="contacts__soc">
                                <a
                                    href={telegram}
                                    onClick={() => ym('reachGoal', 'telegram')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="contacts__soc-link"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                        <path d="M15 1.87549C7.75125 1.87549 1.875 7.75174 1.875 15.0005C1.875 22.2492 7.75125 28.1255 15 28.1255C22.2487 28.1255 28.125 22.2492 28.125 15.0005C28.125 7.75174 22.2487 1.87549 15 1.87549ZM21.5559 10.2005C21.3478 12.3849 20.4487 17.6846 19.9912 20.1305C19.7972 21.1655 19.4166 21.5124 19.0472 21.5461C18.2447 21.6202 17.6362 21.0164 16.8591 20.5064C15.6431 19.7095 14.9569 19.2136 13.7766 18.4355C12.4125 17.5364 13.2966 17.0433 14.0737 16.2361C14.2772 16.0252 17.8116 12.8105 17.88 12.5189C17.8884 12.4824 17.8969 12.3464 17.8153 12.2752C17.7347 12.203 17.6156 12.2283 17.5294 12.2471C17.4075 12.2752 15.465 13.5586 11.7037 16.0983C11.1525 16.477 10.6528 16.6608 10.2056 16.6514C9.7125 16.6411 8.76375 16.373 8.05875 16.1433C7.19344 15.8621 6.50625 15.7139 6.56625 15.2358C6.59719 14.9874 6.94031 14.7324 7.59375 14.4727C11.6203 12.7186 14.3053 11.5617 15.6487 11.003C19.4841 9.40736 20.2819 9.1308 20.8012 9.12143C20.9156 9.11955 21.1706 9.14768 21.3366 9.28174C21.4762 9.39517 21.5147 9.54799 21.5334 9.6558C21.5522 9.76361 21.5747 10.0083 21.5569 10.1995L21.5559 10.2005Z" fill="white"/>
                                    </svg>
                                </a>
                                <a
                                    href={vk}
                                    onClick={() => ym('reachGoal', 'vkontakte')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="contacts__soc-link"
                                >
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.5769 0.79541C7.6112 0.79541 1.15387 7.25274 1.15387 15.2185C1.15387 23.1842 7.6112 29.6416 15.5769 29.6416C23.5427 29.6416 30 23.1842 30 15.2185C30 7.25274 23.5427 0.79541 15.5769 0.79541ZM21.1238 17.0679C21.1238 17.0679 22.3994 18.327 22.7134 18.9114C22.7224 18.9234 22.7269 18.9354 22.7299 18.9414C22.8576 19.1563 22.8876 19.3231 22.8245 19.4478C22.7194 19.6551 22.3588 19.7572 22.2356 19.7663H19.982C19.8257 19.7663 19.4982 19.7257 19.1016 19.4523C18.7966 19.2389 18.4961 18.8889 18.2031 18.5478C17.7659 18.04 17.3873 17.6013 17.0057 17.6013C16.9573 17.6012 16.9091 17.6088 16.863 17.6238C16.5745 17.717 16.205 18.1286 16.205 19.2254C16.205 19.5679 15.9345 19.7648 15.7437 19.7648H14.7116C14.36 19.7648 12.5286 19.6416 10.906 17.9303C8.91979 15.8345 7.13193 11.6307 7.11691 11.5917C7.00423 11.3197 7.2371 11.174 7.49101 11.174H9.76715C10.0706 11.174 10.1698 11.3588 10.2389 11.5226C10.32 11.7134 10.6175 12.4721 11.1058 13.3255C11.8976 14.7167 12.3828 15.2816 12.772 15.2816C12.8449 15.2807 12.9166 15.2622 12.9808 15.2275C13.4886 14.945 13.394 13.1347 13.3714 12.7591C13.3714 12.6884 13.3699 11.9493 13.11 11.5947C12.9237 11.3378 12.6067 11.2401 12.4144 11.2041C12.4922 11.0967 12.5948 11.0096 12.7134 10.9502C13.0619 10.7759 13.6899 10.7503 14.3134 10.7503H14.6605C15.3366 10.7594 15.5108 10.8029 15.7557 10.8645C16.2515 10.9832 16.262 11.3032 16.2185 12.3985C16.205 12.7095 16.1914 13.061 16.1914 13.4757C16.1914 13.5658 16.1869 13.662 16.1869 13.7642C16.1719 14.3216 16.1539 14.9541 16.5475 15.214C16.5988 15.2462 16.6582 15.2633 16.7188 15.2636C16.8555 15.2636 17.2671 15.2636 18.3819 13.351C18.7257 12.7354 19.0245 12.0957 19.2759 11.4369C19.2984 11.3979 19.3645 11.2777 19.4426 11.2311C19.5003 11.2017 19.5642 11.1868 19.6289 11.1875H22.3047C22.5962 11.1875 22.796 11.2311 22.8336 11.3438C22.8997 11.5226 22.8215 12.0679 21.6001 13.7221L21.0547 14.4417C19.9474 15.8931 19.9474 15.9667 21.1238 17.0679Z" fill="white"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WithDeviceType(ContactsSection);
