import React from 'react';
import './index.scss';

const BlackButton = ({children, ...props}) => {
  return (
    
    <div {...props} className='label-text black-button'>
        <span>{children}</span>
    </div>
  )
}

export default BlackButton