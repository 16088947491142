import React from 'react';
import { isSafari } from 'react-device-detect';
import ym from 'react-yandex-metrika';
import { fb, inst, vk, be, youtube, telegram } from '../../../../constants';
import { useTextAnimation } from '../../../../hooks';
import './scss/HeaderSection.scss';
import gradientVideo from '../../../../assets/images/Gradient-27 2_1.webm';

const HeaderSection = () => {
    const { wordRef, initialWord } = useTextAnimation([
        'амбициозных',
        'e-commerce',
        'производственных',
        'государственных',
        'технологических'
    ]);

    return (
        <section className="section header">
            {/* <img src={headerImg} alt="" className='header__bg' /> */}
            <video loop muted autoplay="autoplay" width="100%" height="100%" className='header__bg'>
                <source src={gradientVideo} type="video/webm" />
            </video>
            <div className="wrapper wrapper_header">
    
            </div>
        </section>
    );
};

export default HeaderSection;
