import React from 'react';
import PurpleTitle from '../../Common/PurpleTitle';
import ServicesSection from '../MainPage/MainPageSections/ServicesSection';
import ContactsSection from '../MainPage/MainPageSections/ContactsSection';
import './404.scss';
import Helmet from 'react-helmet';
import WithTransition from '../../HOCs/WithTransition';
import ToTopBtn from '../../Common/ToTopBtn';

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <title>404</title>
        <meta name="description" content="404 component" />
      </Helmet>
      <section className="notfound">
        <div className="wrapper">
          <PurpleTitle title="Страница не найдена" />
          <div className="notfound__title-wrapper" data-aos="swipe-left">
            <span className="notfound__title" data-aos="fade-up">
              Ошибка 404
            </span>
          </div>
        </div>
      </section>
      <ServicesSection is404={true} />
      <ContactsSection />
      {/* <ToTopBtn /> */}
    </>
  );
};

export default WithTransition(PageNotFound);
