import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { servicesAPI } from '../../api';
import { LayoutBlock, LayoutWrapper } from '../Layout';
import clsx from 'clsx';
import ym from 'react-yandex-metrika';
import UnderlineButton from '../Common/UnderlineButton';
import WithDeviceType from '../HOCs/WithDeviceType';
import { ServicesBlockItem } from './Components/ServicesBlockItem';
import './index.scss';

const ServicesBlock = (props) => {
  const { desktop, className, ...other } = props;
  const [services, setservices] = useState([]);
  useEffect(() => {
    servicesAPI.getServices().then((res) => setservices(res));
  }, []);

  return (
    <div className={clsx('servicesBlock', className)} {...other} style={{marginTop: '4rem'}}>
      <LayoutWrapper> 
      <div className="servicesBlock__box">
          <LayoutBlock className="servicesBlock__description">
            <h2 className="servicesBlock__title">Услуги</h2>
            <div className="servicesBlock__text">
              Дизайн сложных интерфейсов, full&#8209;stack разработка, тестирование,
              автоматизация бизнес&#8209;процессов.
              Сочетаем компетенции, опыт и&nbsp;технологии для создания эффективных
              IT&#8209;решений
            </div>
            {/* <UnderlineButton
              tag={NavLink}
              to={`/services`}
              className="servicesBlock__moreBtn"
              onClick={() => ym('reachGoal', 'moreservices')}
              data-aos="fade-up"
            >
              Подробнее об услугах
            </UnderlineButton> */}
          </LayoutBlock>
          <LayoutBlock className="servicesBlock__list">
            {services.map((service, i) => {
              return (
                <ServicesBlockItem
                  key={service.id}
                  data-aos-delay={(i + 1) * 100}
                  service={service}
                />
              );
            })}
          </LayoutBlock>
          </div>
      </LayoutWrapper>
    </div>
  );
};

export default WithDeviceType(ServicesBlock);
