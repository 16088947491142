import React from 'react';
import './scss/FactsTable.scss';

const FactsTable = ({facts, main}) => {
    return (
        <div className={`facts-table ${main ? 'facts-table_main' : ''}`}>
            {facts.map((item, i) => {
                return <div key={i} className="facts-table__item" data-aos="fade-up">
                    <div className="facts-table__item-content">
                        <span className="facts-table__item-num">{item.num}</span>
                        <p className="facts-table__item-text">{item.text}</p>
                    </div>

                </div>
            })}
        </div>
    );
};

export default FactsTable;
