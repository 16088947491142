import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import Alert from '../../Common/Alert';
import { promoAPI } from '../../../api';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { useHistory } from 'react-router-dom';
import './index.scss';

Modal.setAppElement('#root');

const MainModal = ({ desktop, modalIsOpen, closeModal }) => {
  const history = useHistory();
  const modalStyles = {
    overlay: {
      zIndex: 30,
      background: 'rgba(0,0,0,0.5)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: desktop ? '37.5rem' : '100%',
      height: desktop ? 'auto' : '100vh',
      backgroundColor: '#fff',
      padding: '2.4375rem 3.375rem 2.875rem 2.6875rem'
    }
  };
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({});

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [link, setLink] = useState('');
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState(new Set());

  const refName = useRef(null);
  const refPhone = useRef(null);
  const refLink = useRef(null);
  const refComment = useRef(null);

  const clearForm = () => {
    setName('');
    setPhone('');
    setLink('');
    setComment('');
    setErrors(new Set());
  };
  const validate = (inputName, inputValue) => {
    switch (inputName) {
      case 'phone':
        return !!inputValue.match(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){6,14}(\s*)?$/);
      default:
        return true;
    }
  };
  const validateForm = () => {
    const inputs = [refName.current, refPhone.current, refLink.current, refComment.current];

    let newErrors = new Set(errors);
    inputs.forEach((item) => {
      validate(item.name, item.value) ? newErrors.delete(item.name) : newErrors.add(item.name);
    });

    setErrors(newErrors);
    return newErrors.size;
  };
  const sendForm = (e) => {
    e.preventDefault();
    let errorsSize = validateForm();

    if (!errorsSize) {
      let formData = new FormData();
      !!name && formData.append('name', name);
      !!phone && formData.append('phone', phone);
      !!link && formData.append('link', link);
      !!comment && formData.append('comment', comment);
      formData.append('type', 'audit');

      promoAPI
        .sendAudit(formData)
        .then(() => {
          clearForm();
          closeModal();
          history.push('/success');
          ym('reachGoal', 'zayavka-na-audit-otpravka-formy');
          ReactPixel.track('SubmitApplication');
          ReactGA.event({
            category: 'appayer_sur_le_bouton',
            action: 'envoyer_loffre',
            label: 'distuter_du_projet_premier_bloc'
          });
        })
        .catch(() => {
          closeModal();
          setAlertContent({
            title: '',
            isErrorAlert: true,
            text: 'Что-то пошло не так, попробуйте отправить заявку позже.',
            buttonText: 'Вернуться',
            onClose: () => setShowAlert(false)
          });
          setShowAlert(true);
        });
    }
  };
  const onInputChange = (e) => {
    let val = e.target.value;
    switch (e.target.name) {
      case 'phone':
        setPhone(val);
        break;
      case 'link':
        setLink(val);
        break;
      case 'comment':
        setComment(val);
        break;
      default:
        setName(val);
    }
  };

  return (
    <>
      {showAlert && <Alert {...alertContent} />}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={modalStyles}>
        <button type="button" className="headerModal__close" onClick={closeModal}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="-1"
              x2="24.8353"
              y2="-1"
              transform="matrix(0.68451 -0.729003 0.68451 0.729003 2 19.8599)"
              stroke="#CECECE"
              strokeWidth="2"
            />
            <line
              y1="-1"
              x2="24.8353"
              y2="-1"
              transform="matrix(-0.68451 -0.729003 -0.68451 0.729003 18 19.8599)"
              stroke="#CECECE"
              strokeWidth="2"
            />
          </svg>
        </button>
        <form>
          <h2 className="headerModal__title">Мы свяжемся с вами в течение 2-х часов</h2>
          <input
            ref={refName}
            type="text"
            className="headerModal__input"
            value={name}
            name="name"
            onChange={onInputChange}
            placeholder="Имя"
          />
          <input
            ref={refPhone}
            type="text"
            className={`headerModal__input ${
              errors.has('phone') ? 'headerModal__input_error' : ''
            }`}
            value={phone}
            name="phone"
            onChange={onInputChange}
            placeholder="Телефон"
          />
          <input
            ref={refLink}
            type="text"
            className="headerModal__input"
            value={link}
            name="link"
            onChange={onInputChange}
            placeholder="Ссылка на сайт или соцсети"
          />
          <textarea
            ref={refComment}
            className="headerModal__inputArea"
            name="comment"
            onChange={onInputChange}
            placeholder="Комментарий"
          />
          <button type="submit" className="headerModal__submit" onClick={sendForm}>
            Отправить заявку
          </button>
        </form>
      </Modal>
    </>
  );
};

export default MainModal;
