import { useEffect, useRef, useState } from 'react';
import { mobileWidth, tabletWidth } from './constants';

export const useDeviceType = () => {
  const [desktop, setDesktop] = useState(true);
  const [tablet, setTablet] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(document.documentElement.offsetWidth);

  useEffect(() => {
    if (windowWidth <= tabletWidth && windowWidth > mobileWidth) {
      setTablet(true);
      setDesktop(false);
    }

    if (windowWidth <= mobileWidth) {
      setMobile(true);
      setDesktop(false);
    }
  }, []);

  return { desktop, tablet, mobile, windowWidth };
};

export const useTextAnimation = (wordsArray, duration = 3000) => {
  const wordRef = useRef(null);
  const changeWordTimer = useRef(null);

  useEffect(() => {
    changeTextAnimation(wordRef.current);

    return () => {
      clearInterval(changeWordTimer.current);
    };
  }, []);

  const changeTextAnimation = () => {
    if (wordRef.current) {
      let i = 0,
        word;
      changeWordTimer.current = setInterval(() => {
        i + 1 >= wordsArray.length ? (i = 0) : i++;
        word = wordsArray[i].split('');

        wordRef.current.innerHTML = '';
        for (let i = 0; i < word.length; i++) {
          setTimeout(() => {
            const letterSpan = document.createElement('span');
            letterSpan.innerHTML = word[i];
            wordRef.current.appendChild(letterSpan);
            letterSpan.style.display = 'inline-block';
          }, 100 * i);
        }
      }, duration);
    } else {
      clearInterval(changeWordTimer.current);
    }
  };

  return { wordRef, initialWord: wordsArray[0] };
};

export const useScroll = (ref) => {
  useEffect(() => {
    setTimeout(() => {
      let { hash } = window.location;
      hash = hash.replace('#', '');
      if (ref?.current?.id === hash) ref.current.scrollIntoView();
    }, 2000);
  }, []);
};
