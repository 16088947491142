import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import ContactsSection from '../MainPage/MainPageSections/ContactsSection';
import Articles from '../MainPage/MainPageSections/ArticlesSection';
import './Articles.scss';
import ToTopBtn from '../../Common/ToTopBtn';

const ArticlesPage = ({ toggleMenu }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    toggleMenu(false);
  }, []);

  return (
    <>
      <Helmet>
        <title>Статьи</title>
        <meta name="description" content="Articles component" />
      </Helmet>
      <Articles />
      <ContactsSection />
      {/* <ToTopBtn /> */}
    </>
  );
};

export default ArticlesPage;
