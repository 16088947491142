import React, {useEffect, useState} from 'react';
import './scss/ServicesSection.scss';
import WithDeviceType from "../../../HOCs/WithDeviceType";
import {NavLink} from "react-router-dom";
import {servicesAPI} from "../../../../api";
import ym from "react-yandex-metrika";


const ServicesSection = ({desktop, is404}) => {
    const [services, setServices] = useState([]);

    useEffect(() => {
        servicesAPI.getServicesTitles().then(res => setServices(res))
    },[]);

    return (
        <section className={`section search ${is404 ? 'search_404' : ''}`}>
            <div className="wrapper search__wrapper">
                <div className="search__info">
                    <h2 className="search__title title" data-aos="fade-up">Услуги</h2>
                    {!is404 && <p className="search__text" data-aos="fade-up">Дизайн сложных интерфейсов, full-stack разработка, комплекс рекламных каналов, автоматизация бизнес-процессов.
                        Сочетаем компетенции, опыт и&nbsp;технологии для создания эффективных digital-решений.</p>}
                    {desktop &&  <NavLink to={`/services`} onClick={() => ym('reachGoal', 'moreservices')}  className="search__showMore" data-aos="fade-up">Подробнее об услугах</NavLink>}
                </div>
                <div className="search__list">
                    {services.map((item, i) => {
                        return <NavLink to={`/services/${item.link}`} key={item.id} className="search__item" data-aos="fade-up" data-aos-delay={(i + 1) * 100}>
                            <span className="search__item-title">{item.title}</span>
                            <span className="search__item-num">{i < 9 ? `0${i + 1}` : i + 1}</span>
                        </NavLink>
                    })}
                </div>
                {!desktop &&  <NavLink to={`/services`} onClick={() => ym('reachGoal', 'moreservices')} className="search__showMore" data-aos="fade-up">Подробнее об услугах</NavLink>}
            </div>
        </section>
    );
}

export default WithDeviceType(ServicesSection);
