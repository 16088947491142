import React, { useState, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';

import WithDeviceType from '../../HOCs/WithDeviceType';
import { useScroll } from '../../../hooks';
import './index.scss';

const Cards = ({ api, purpleColor }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    api.sendInfo('promoCards').then((res) => setData(res));
  }, []);

  const ref = useRef(null);
  useScroll(ref);

  return (
    <div className="wrapper" id="cards" ref={ref}>
      <div className="cards" data-aos="fade-up">
        {data?.map((item, index) => (
          <div className="card" key={`card--${index}`}>
            <div className={`card__title ${purpleColor ? 'purpleColor' : ''}`}>
              <h3>{ReactHtmlParser(item.title)}</h3>
            </div>
            <div className="card__desc">
              <p>{ReactHtmlParser(item.subtitle)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WithDeviceType(Cards);
