import React from 'react';
import './index.scss';

const AwardsItem = (props) => {
  const { award } = props;
  return (
    <div className="awardsItem">
      <div className="awardsItem__content">
        <span className="awardsItem__title">{award.title}</span>
        {award.category && (
          <a href={award.link} target="_blank" className="awardsItem__description">
            {award.category}
          </a>
        )}
      </div>
      <div className="awardsItem__place">{award.position}</div>
    </div>
  );
};

export default AwardsItem;
