import React, { useEffect, useState } from 'react';
import ContactsSection from '../MainPage/MainPageSections/ContactsSection';
import WithDeviceType from '../../HOCs/WithDeviceType';
import Helmet from 'react-helmet';
import { useAppContext } from '../../../AppContext';
import WithTransition from '../../HOCs/WithTransition';
import '../MainPage/MainPage.scss';
import { NavLink, useHistory } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import TextSection from '../MainPage/MainPageSections/TextSection';
import SocialSection from '../MainPage/MainPageSections/SocialSection';
import BlackButton from '../../Common/BlackButton/BlackButton';
import TitleSection from '../../TitleSection/TitleSection';
import './NedwizPage.scss';
import FactsSlider from '../../FactsSlider/FactsSlider';
import ExpertizaSection from '../MainPage/MainPageSections/ExpertizaSection';
import { useDeviceType } from '../../../hooks';
import regard from '../../../assets/images/projects/newProjects/regard.png';
import citimix from '../../../assets/images/projects/newProjects/citimix.png';
import sawatzky from '../../../assets/images/projects/newProjects/sawat-lk.png';
import sawatzkyMob from '../../../assets/images/projects/newProjects/sawat-abstr.png'


const NedwizPage = ({ isIndexPage, setIsIndexPage }) => {

    const { initPage, mainRef } = useAppContext();
    const projectsFirst = [
        {
            "id": 149,
            "title": "Регард",
            "tags": [
                "Web-сервис",
            ],
            "types": [
                "PropTech"
            ],
            "link": "regard",
            "pic": regard,
            "description": "Web-сервис для оптимизации ведения отчетов геодезийных исследований.",
        },
        {
            "id": 150,
            "title": "Citymix",
            "tags": [
                "Личный кабинет",
            ],
            "types": [
                "PropTech"
            ],
            "link": "citimix",
            "pic": citimix,
            "description": "Автоматизация процесса бронирования недвижимости и оформления сделки в регистрационной палате с 5 интеграциями.",
        },
        {
            "id": 151,
            "title": "Sawatzky",
            "tags": [
                "Личный кабинет",
            ],
            "types": [
                "PropTech",
                "ERP"
            ],
            "link": "sawatzky",
            "pic": sawatzky,
            "description": "С нуля запустили личный кабинет для клиентов компании.",
        },
        {
            "id": 152,
            "title": "Sawatzky",
            "tags": [
                "Мобильное приложение",
            ],
            "types": [
                "PropTech",
                "ERP"
            ],
            "link": "sawatzky-erp",
            "pic": sawatzkyMob,
            "description": "Развиваем ERP-систему и мобильное приложение, которые автоматизирует все бизнес-процессы компании. Системой ежедневно пользуется более 1000 сотрудников.",
        },
    ]

    const { mobile } = useDeviceType();
    const history = useHistory();

    const [isProjectsPage] = useState(history.location.pathname === '/projects');

    const getImgSrc = (index, item) => {
        if (isProjectsPage) {
            return index !== 0 && (index + 1) % 3 === 0 ? item.mainPic : item.pic;
        }
        return item.pic;
    };
    useEffect(() => {
        initPage();
    }, []);

    return (
        <div className='nedwiz'>
            <Helmet>
                <title>PropTech</title>
                <meta
                    name="description"
                    content="Digital-решения для амбициозных компаний. Разработка сайтов и цифровых продуктов. Комплексный интернет-маркетинг."
                />

            </Helmet>
            <TitleSection textTitle='PropTech' textContent={`
                Запустили более 10 проектов с 2020 года.
                <br/>
                Среди клиентов - застройщики, агентства недвижимости, строительные и инженерные компании.
            `}>
         
            </TitleSection>
            <ExpertizaSection
                titleText="Проекты"
            >
                <div className="grid-cards">
                    {projectsFirst.map((item, i) => {
                        return (
                            <NavLink
                                key={i}
                                to={{
                                    pathname: `/projects/${item.link}`,
                                    state: {
                                        link: item.link
                                    }
                                }}
                                className="projects-gallery__project"
                            >
                                <div className="projects-gallery__project-pic">
                                    {mobile ? (
                                        <div className="projects-gallery__project-pic-wrapper">
                                            <img
                                                src={getImgSrc(i, item)}
                                                alt={item.title}
                                                className="projects-gallery__project-img"
                                            />
                                        </div>
                                    ) : (
                                        <img
                                            src={getImgSrc(i, item)}
                                            alt={item.title}
                                            className="projects-gallery__project-img"
                                        />
                                    )}
                                </div>

                                <div className="projects-gallery__project-info">
                                    <span className="projects-gallery__project-title">{item.title}</span>
                                    <p className="label-text projects-gallery__text">
                                        {item.description}
                                    </p>
                                    {!mobile && (
                                        <div className="projects-gallery__project-tags">
                                            {item.types.map((tag, i) => (
                                                <span key={i} className="projects-gallery__project-type">
                                                    {tag}
                                                </span>
                                            ))}
                                            {item.tags.map((tag, i) => (
                                                <span key={i} className="projects-gallery__project-tag">
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                {mobile && (
                                    <div className="projects-gallery__project-tags">
                                        {item.types.map((tag, i) => (
                                            <span key={i} className="projects-gallery__project-type">
                                                {tag}
                                            </span>
                                        ))}
                                        {item.tags.map((tag, i) => (
                                            <span key={i} className="projects-gallery__project-tag">
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </NavLink>
                        );
                    })}
                </div>
            </ExpertizaSection>
            <TextSection>
                Подтверждённая отраслевая экспертиза в разработке для сферы управления недвижимостью.
                <br/>
                <br/>
                Корпоративное ПО, личные кабинеты, мобильные приложения, корпоративные сайты, интеграции с внешними сервисами.
            </TextSection>
            <SocialSection
                titleText="Цитата"
                personText="Мы знаем, как зарабатывать на IT-продуктах, и можем говорить на языке бизнеса."
            >
                <NavLink
                    to={`/brief`}
                    onClick={() => {
                        ReactGA.event({
                            category: 'appayer_sur_le_bouton',
                            action: 'aller_sur_une_autre_page',
                            label: 'aller_sur_une_autre_page_toit_de_site'
                        });
                        ym('reachGoal', 'startproject');
                    }}
                    className=""
                >
                    <BlackButton>Запустим продукт вместе</BlackButton>
                </NavLink>
            </SocialSection>
            <ContactsSection />
        </div>
    )
}

export default WithTransition(WithDeviceType(NedwizPage))