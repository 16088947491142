import React, {useState, useEffect} from 'react';
import { awardsAPi } from '../../api';
import { awards } from '../../constants'
import { LayoutBlock, LayoutWrapper } from '../Layout';
import AwardsItem from './AwardsItem';
import './index.scss';

const AwardsSection = () => {
  // const [awards, setAwards] = useState([])
  // useEffect(() => {
  //   // awardsAPi.getAwards().then((res) => setAwards(res));
  //   setAwards
  // }, []);

  return (
    <section className="awardsSection">
      <LayoutWrapper>
        <div className="awardsSection__box">
          <LayoutBlock className="awardsSection__description">
            <h2 className="awardsSection__title title">Награды</h2>
          </LayoutBlock>
          <LayoutBlock className="awardsSection__list">
            {awards && awards.map((award, i) => {
              return <AwardsItem key={`${award.id}${i}`} data-aos-delay={(i + 1) * 100} award={award} />;
            })}
          </LayoutBlock>
        </div>
      </LayoutWrapper>
    </section>
  );
};

export default AwardsSection;
