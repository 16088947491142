import React, { useEffect, useState } from 'react';
import { ProjectsGrid } from '../../Common/ProjectsGrid';
import ProjectsList from './ProjectsList';
import './Projects.scss';
import ContactsSection from '../MainPage/MainPageSections/ContactsSection';
import Helmet from 'react-helmet';
import PurpleTitle from '../../Common/PurpleTitle';
import { projectsAPI } from '../../../api';
import WithDeviceType from '../../HOCs/WithDeviceType';
import { NavLink } from 'react-router-dom';
import { useAppContext } from '../../../AppContext';
import WithTransition from '../../HOCs/WithTransition';
import ym from 'react-yandex-metrika';
import { useRef } from 'react';
import ToTopBtn from '../../Common/ToTopBtn';

const ProjectsPage = ({ mobile }) => {
  const [categories, setCategories] = useState([]);
  const [activeCategoryTitle, setActiveCategoryTitle] = useState('Все');
  const [activeCategory, setActiveCategory] = useState(0);
  const [previewProjects, setPreviewProjects] = useState([]);
  const [projects, setProjects] = useState([]);
  const [openedSelect, setOpenedSelect] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [tableHeight, setTableHeight] = useState('auto');

  const { initPage, mainRef } = useAppContext();
  const filterRef = useRef(null);
  useEffect(() => {
    initPage();

    projectsAPI.getCategories().then((res) => setCategories(res));
    projectsAPI.getPreviewProjects().then((res) => {
      setPreviewProjects(res);
    });
    projectsAPI.getProjects().then((res) => {
      setProjects(res.items);
      setCurPage(res.currentPage);
      setTotalPages(res.totalPages);
      console.log(res)
    });
  }, []);

  const loadMore = () => {
    setIsLoading(true);
    ym('reachGoal', 'showmore');
    projectsAPI.getProjects(curPage + 1, activeCategory).then((res) => {
      setProjects((prev) => [...prev, ...res.items]);
      setCurPage(res.currentPage);
      setTotalPages(res.totalPages);
      setIsLoading(false);
    });
  };
  const scrollToRef = (ref) => {
    const offset = ref.current.offsetTop;
    window.scrollTo({ top: offset });
  };
  const changeCategory = function (categoryID) {
    // const list = mainRef.current.querySelector('.projects-list__table');
    // const listHeight = list.offsetHeight + 'px';
    // setTableHeight(listHeight);

    projectsAPI.getPreviewProjects(categoryID).then((res) => {
      setPreviewProjects(res);
      scrollToRef(filterRef);
    });
    setActiveCategory(categoryID);

    projectsAPI.getProjects(1, categoryID).then((res) => {
      setProjects(res.items);
      setCurPage(1);
      setTotalPages(res.totalPages);
      // setTableHeight('auto');
    });

    if (categoryID === 0) {
      setActiveCategoryTitle('Все');
    } else {
      for (let i = 0; i < categories.length; i++) {
        if (categories[i].id === categoryID) {
          setActiveCategoryTitle(categories[i].title);
          break;
        }
      }
    }
  };

  const toggleSelect = () => {
    setOpenedSelect((prev) => !prev);
  };

  return (
    <>
      <Helmet>
        <title>Проекты</title>
        <meta name="description" content="Projects component" />
      </Helmet>
      <section className="projects">
        <div className="wrapper">
          <PurpleTitle title="Проекты" />
          {(!!previewProjects.length || !!projects.length) && (
            <div className="industries" ref={filterRef}>
              <h4 className="industries__title">Отрасли</h4>
              {!mobile ? (
                <div className="industries__buttons">
                  <button
                    onClick={() => changeCategory(0)}
                    className={`industries__button ${
                      activeCategory === 0 ? 'industries__button_active' : ''
                    }`}
                  >
                    Все
                  </button>
                  {categories.map((item) => {
                    return (
                      <button
                        key={item.id}
                        onClick={() => changeCategory(item.id)}
                        className={`industries__button ${
                          activeCategory === item.id ? 'industries__button_active' : ''
                        }`}
                      >
                        {item.title}
                      </button>
                    );
                  })}
                </div>
              ) : (
                <div
                  onClick={toggleSelect}
                  className={`industries__select ${
                    openedSelect ? 'industries__select_opened' : ''
                  }`}
                >
                  <span className="industries__select-cur">{activeCategoryTitle}</span>
                  <div className="industries__select-list">
                    <button
                      onClick={() => changeCategory(0)}
                      className={`industries__select-item ${
                        activeCategory === 0 ? 'industries__select-item_active' : ''
                      }`}
                    >
                      Все
                    </button>
                    {categories.map((item) => (
                      <button
                        key={item.id}
                        onClick={() => changeCategory(item.id)}
                        className={`industries__select-item ${
                          activeCategory === item.id ? 'industries__select-item_active' : ''
                        }`}
                      >
                        {item.title}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {!!previewProjects.length && <ProjectsGrid projects={previewProjects} />}
        </div>
      </section>
      {!!projects.length && (
        <section className="projects-list">
          <div className="wrapper">
            {projects.length > 0 && (
              <ProjectsList
                projects={projects}
                loadMore={loadMore}
                curPage={curPage}
                totalPages={totalPages}
                tableHeight={tableHeight}
                isLoading={isLoading}
              />
            )}
          </div>
        </section>
      )}
      <ContactsSection />
      {/* <ToTopBtn /> */}
    </>
  );
};

export default WithTransition(WithDeviceType(ProjectsPage));
