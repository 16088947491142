import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperCore, { Navigation } from 'swiper';

import WithDeviceType from '../../HOCs/WithDeviceType';
import { reinitSwiper } from '../../functions/functions';
import { useScroll } from '../../../hooks';
import './index.scss';

const Projects = ({ desktop, projects, style }) => {
  SwiperCore.use([Navigation]);

  const initNav = (swiper) => {
    swiper.navigation.prevEl.innerHTML = `<svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7618 39L-6.45189e-06 19.5L19.7618 2.30769e-06L23.7142 3.9L10.6995 16.7423L37 17.0098L37 22.5252L10.6995 22.2577L23.7142 35.1L19.7618 39Z" fill="url(#paint0_radial)"/>
    <defs>
        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.6814 39) rotate(-132.095) scale(50.2437 72.0712)">
            <stop offset="0.0272715" stop-color="#FA3D33"/>
            <stop offset="1" stop-color="#EE8B52"/>
        </radialGradient>
    </defs>
</svg>`;
    swiper.navigation.nextEl.innerHTML = `<svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.2382 3.01402e-06L37 19.5L17.2382 39L13.2858 35.1L26.3005 22.2577L-7.55217e-06 21.9902L-5.65799e-06 16.4748L26.3005 16.7423L13.2858 3.9L17.2382 3.01402e-06Z" fill="url(#paint0_radial)"/>
    <defs>
        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.31856) rotate(47.9051) scale(50.2437 72.0712)">
            <stop offset="0.0272715" stop-color="#FA3D33"/>
            <stop offset="1" stop-color="#EE8B52"/>
        </radialGradient>
    </defs>
</svg>`;
  };

  const ref = useRef(null);
  useScroll(ref);

  return (
    <section id="portfolio" ref={ref} className="section projectSwipper" style={style}>
      <div className="wrapper projectSwipper__wrapper">
        <div className="projectSwipper__header">
          <h2 className="projectSwipper__title" data-aos="fade-up">
            Проекты
          </h2>
        </div>
        <div className="projectSwipper__slider">
          <Swiper
            data-aos="fade-in"
            data-aos-delay="300"
            spaceBetween={20}
            navigation
            slidesPerView={desktop ? 2 : 1}
            onSwiper={(swiper) => {
              reinitSwiper(swiper);
              initNav(swiper);
            }}
          >
            {projects.map((item, i) => {
              return (
                <SwiperSlide
                  key={i}
                  className="projectSwipper__slide"
                  data-aos="fade-up"
                  data-aos-delay={(i + 1) * 100}
                >
                  <div className="projectSwipper__slide-container">
                    <a href={item.link} className="projectSwipper__slide-ref" target="_blank">
                      <img src={item.pic} alt={item.title} className="projectSwipper__slide-pic" />
                      <div className="projectSwipper__tags">
                        {item.tags.map((tag, index) => (
                          <span className="projectSwipper__tag" key={`tag--${index}`}>
                            {tag}
                          </span>
                        ))}
                      </div>
                    </a>
                  </div>
                  <span className="projectSwipper__slide-name">{item.title}</span>
                  <span className="projectSwipper__slide-position">{item.description}</span>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default WithDeviceType(Projects);
