import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDeviceType } from '../../hooks';
import './scss/ProjectsGrid.scss';

export const ProjectsGrid = ({ projects, isClassicGrid }) => {
  const { mobile } = useDeviceType();
  const history = useHistory();

  const [isProjectsPage] = useState(history.location.pathname === '/projects');

  const getImgSrc = (index, item) => {
    if (isProjectsPage) {
      return index !== 0 && (index + 1) % 3 === 0 ? item.mainPic : item.pic;
    }
    return item.pic;
  };

  return (
    <div className={`projects-gallery ${!isClassicGrid ? 'projects-gallery_notClassic' : ''}`}>
      {projects.map((item, i) => {
        return (
          <NavLink
            key={item.id}
            to={{
              pathname: `/projects/${item.link}`,
              state: {
                link: item.link
              }
            }}
            data-aos="fade-up"
            className="projects-gallery__project"
          >
            <div className="projects-gallery__project-pic">
              {mobile ? (
                <div className="projects-gallery__project-pic-wrapper">
                  <img
                    src={item.mainPic}
                    alt={item.title}
                    className="projects-gallery__project-img"
                  />
                </div>
              ) : (
                <img
                  // src={getImgSrc(i, item)}
                  src={getImgSrc(i, item)}
                  alt={item.title}
                  className="projects-gallery__project-img"
                />
              )}
            </div>
            
            <div className="projects-gallery__project-info">
              <span className="projects-gallery__project-title">{item.title}</span>
              {/* {isProjectsPage && (
                <p className="projects-gallery__project-desc">{item.description}</p>
                )
              } */}
              {!mobile && (
                  <div className="projects-gallery__project-tags">
                    {item.types.map((tag) => (
                      <span key={tag.id} className="projects-gallery__project-type">
                        {tag}
                      </span>
                    ))}
                    {item.tags.map((tag) => (
                      <span key={tag.id} className="projects-gallery__project-tag">
                        {tag}
                      </span>
                    ))}
                  </div>
              )}
            </div>

            {mobile && (
                <div className="projects-gallery__project-tags">
                  {item.types.map((tag) => (
                    <span key={tag.id} className="projects-gallery__project-type">
                      {tag}
                    </span>
                  ))}
                  {item.tags.map((tag, i) => (
                    <span key={i} className="projects-gallery__project-tag">
                      {tag}
                    </span>
                  ))}
                </div>
            )}
          </NavLink>
        );
      })}
    </div>
  );
};
