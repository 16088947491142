import React, { useEffect } from 'react';
import AgencyStructure from './AgencyPageSections/AgencyStructure';
import './Agency.scss';
import AgencyTeam from './AgencyPageSections/AgencyTeam';
import AgencyApproach from './AgencyPageSections/AgencyApproach';
import ContactsSection from '../MainPage/MainPageSections/ContactsSection';
import ServicesBlock from '../../ServicesBlock';
import Helmet from 'react-helmet';
import ProjectsGallery from '../../Common/ProjectsGallery';
import { useAppContext } from '../../../AppContext';
import AwardsSection from '../../AwardsSection';
import WithTransition from '../../HOCs/WithTransition';
import ToTopBtn from '../../Common/ToTopBtn';

const AgencyPage = () => {
  const { initPage, mainRef } = useAppContext();

  useEffect(() => {
    initPage();
  }, []);

  return (
    <>
      <Helmet>
        <title>Компания</title>
        <meta name="description" content="Agency component" />
      </Helmet>
      <div className="agency__info">
        <AgencyStructure mainRef={mainRef} />
        <AgencyTeam mainRef={mainRef} />
      </div>
      <AgencyApproach />
      <ServicesBlock />
      <AwardsSection />
      <ProjectsGallery mainRef={mainRef} />
      <ContactsSection />
      {/* <ToTopBtn /> */}
    </>
  );
};

export default WithTransition(AgencyPage);
