import React, { useState, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useScroll } from '../../../hooks';
import './index.scss';

const Results = ({ api, style }) => {
  const [marketing, setMarketing] = useState([]);

  useEffect(() => {
    api.sendInfo('marketing').then((res) => setMarketing(res));
  }, []);

  const ref = useRef(null);
  useScroll(ref);
  return (
    <section className="solutions" id="solutions" ref={ref} style={style}>
      <div className="wrapper">
        <div className="solutions__marketing" data-aos="fade-up">
          <div className="solutions__column">
            <h2 className="solutions__title">{ReactHtmlParser(marketing?.title)}</h2>
          </div>
          <div className="solutions__grid">
            {marketing?.leftList?.map((item, index) => (
              <div key={item} className="solutions__block">
                <span className="solutions__hyphen">—</span>
                <li className="solutions__item" key={`marketing--${index}`}>
                  {ReactHtmlParser(item)}
                </li>
              </div>
            ))}
            {marketing?.rightList?.map((item, index) => (
              <div key={item} className="solutions__block">
                <span className="solutions__hyphen">—</span>
                <li className="solutions__item" key={`marketing--${index}`}>
                  {ReactHtmlParser(item)}
                </li>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Results;
