import React, { useEffect, useState } from 'react';
import { menuLinks } from '../../constants';
import MobileMenu from './MobileMenu';
import { NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import WithDeviceType from '../HOCs/WithDeviceType';
import './scss/Menu.scss';
import { useAppContext } from '../../AppContext';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import MainModal from '../PromotionBlocks/MainModal';

const Menu = ({ desktop, isIndexPage }) => {
  const [mounted, setMounted] = useState(false);

  const { isOpenedMenu, toggleMenu } = useAppContext();

  const { pathname } = useLocation();

  const setLogo = () => {
    const whiteLogo = (
      <svg xmlns="http://www.w3.org/2000/svg" width="97" height="21" viewBox="0 0 97 21" fill="none">
        <path d="M96.5174 4.2732C96.2761 3.57334 95.7935 3.029 95.2305 2.56243C94.5871 2.09585 93.8632 1.70703 93.1393 1.47375C92.1741 1.24046 91.209 1.16269 90.2438 1.16269L74.1576 1.16269V16.3264L63.7819 1.16269L60.3234 1.16269L49.9478 16.3264C49.9478 16.0931 50.0282 15.8599 50.0282 15.5488V13.2937C50.0282 12.5938 49.9478 11.894 49.7065 11.2719C49.4652 10.572 48.9826 10.0277 48.4196 9.56108C47.7761 9.0945 47.0522 8.70568 46.3284 8.4724C45.3632 8.23911 44.398 8.16134 43.4328 8.16134L32.6551 8.16134C31.1269 8.16134 31.1269 7.53924 31.1269 7.22819V6.13951C31.1269 5.82846 31.1269 5.20636 32.6551 5.20636L49.2239 5.20636V0.929408L32.6551 0.929408C31.6899 0.851646 30.6443 1.00717 29.7595 1.24045C28.9552 1.39598 28.2314 1.7848 27.6683 2.32914C27.1053 2.79571 26.6227 3.34005 26.3814 4.03992C26.1401 4.66202 26.0597 5.36189 26.0597 6.06175V7.15043C26.0597 7.8503 26.1401 8.55016 26.3814 9.17226C26.6227 9.87213 27.1053 10.4165 27.6683 10.883C28.3118 11.3496 29.0357 11.7384 29.7595 11.9717C30.7247 12.205 31.6899 12.2828 32.6551 12.2828L43.5937 12.2828C45.1219 12.2828 45.1219 12.9049 45.1219 13.2159V15.471C45.1219 15.7821 45.1219 16.4042 43.5937 16.4042L27.5075 16.4042L17.0514 1.08493L13.5929 1.08493L0 20.8367H5.87148L15.0406 6.9949L24.2098 20.8367L52.8433 20.8367L62.1733 6.9949L71.3425 20.8367L79.466 20.8367V14.1491L90.4046 14.1491C91.3698 14.2268 92.4154 14.0713 93.3002 13.838C94.1045 13.6825 94.8284 13.2937 95.3914 12.7493C95.9544 12.2828 96.437 11.7384 96.6783 11.0386C96.9196 10.4165 97 9.7166 97 9.01673V6.3728C97 5.67294 96.7587 4.97307 96.5174 4.2732ZM91.772 6.21727V8.93897C91.772 9.25002 91.772 9.87213 90.2438 9.87213L79.3051 9.87213V5.43965L90.2438 5.43965C91.772 5.43965 91.772 5.90622 91.772 6.21727Z" fill="white"/>
      </svg>
    );
    const blackLogo = (
      <svg xmlns="http://www.w3.org/2000/svg" width="97" height="21" viewBox="0 0 97 21" fill="none">
        <path d="M96.5174 4.2732C96.2761 3.57334 95.7935 3.029 95.2305 2.56243C94.5871 2.09585 93.8632 1.70703 93.1393 1.47375C92.1741 1.24046 91.209 1.16269 90.2438 1.16269L74.1576 1.16269V16.3264L63.7819 1.16269L60.3234 1.16269L49.9478 16.3264C49.9478 16.0931 50.0282 15.8599 50.0282 15.5488V13.2937C50.0282 12.5938 49.9478 11.894 49.7065 11.2719C49.4652 10.572 48.9826 10.0277 48.4196 9.56108C47.7761 9.0945 47.0522 8.70568 46.3284 8.4724C45.3632 8.23911 44.398 8.16134 43.4328 8.16134L32.6551 8.16134C31.1269 8.16134 31.1269 7.53924 31.1269 7.22819V6.13951C31.1269 5.82846 31.1269 5.20636 32.6551 5.20636L49.2239 5.20636V0.929408L32.6551 0.929408C31.6899 0.851646 30.6443 1.00717 29.7595 1.24045C28.9552 1.39598 28.2314 1.7848 27.6683 2.32914C27.1053 2.79571 26.6227 3.34005 26.3814 4.03992C26.1401 4.66202 26.0597 5.36189 26.0597 6.06175V7.15043C26.0597 7.8503 26.1401 8.55016 26.3814 9.17226C26.6227 9.87213 27.1053 10.4165 27.6683 10.883C28.3118 11.3496 29.0357 11.7384 29.7595 11.9717C30.7247 12.205 31.6899 12.2828 32.6551 12.2828L43.5937 12.2828C45.1219 12.2828 45.1219 12.9049 45.1219 13.2159V15.471C45.1219 15.7821 45.1219 16.4042 43.5937 16.4042L27.5075 16.4042L17.0514 1.08493L13.5929 1.08493L0 20.8367H5.87148L15.0406 6.9949L24.2098 20.8367L52.8433 20.8367L62.1733 6.9949L71.3425 20.8367L79.466 20.8367V14.1491L90.4046 14.1491C91.3698 14.2268 92.4154 14.0713 93.3002 13.838C94.1045 13.6825 94.8284 13.2937 95.3914 12.7493C95.9544 12.2828 96.437 11.7384 96.6783 11.0386C96.9196 10.4165 97 9.7166 97 9.01673V6.3728C97 5.67294 96.7587 4.97307 96.5174 4.2732ZM91.772 6.21727V8.93897C91.772 9.25002 91.772 9.87213 90.2438 9.87213L79.3051 9.87213V5.43965L90.2438 5.43965C91.772 5.43965 91.772 5.90622 91.772 6.21727Z" fill="#0A0D14"/>
      </svg>
    );
    if (isIndexPage) {
      if (isOpenedMenu) {
        return whiteLogo;
      }
      return whiteLogo;
    } else {
      if (isOpenedMenu) {
        return whiteLogo;
      }
      return blackLogo;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 500);
  }, []);

  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    ym('reachGoal', 'obsudit-proekt');
    ReactGA.event({
      category: 'appayer_sur_le_bouton',
      action: 'distuter_du_projet',
      label: 'distuter_du_projet_premier_bloc'
    });
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={`menu-container ${isOpenedMenu ? 'menu-container_mobile' : ''} ${
        isIndexPage ? '' : 'menu-container_secondary'
      }`}
    >
      {!desktop && <MobileMenu links={menuLinks} />}
      <menu className={`menu ${mounted ? 'mounted' : ''} ${!isIndexPage ? 'header-white' : 'header-white'}`} data-aos="swipe-left">
        
          <div className="menu-blur"></div>
        <div className="wrapper">
          <div className="menu-links">
            <NavLink
              to="/"
              className="menu-title"
              data-aos={desktop ? 'fade-in' : ''}
              data-aos-delay="500"
            >
              {setLogo()}
            </NavLink>
            <ul className="menu-list">
              {menuLinks.map((link, i) => {
                if (i < menuLinks.length - 1) {
                  return (

                    <li key={i} className={`menu-item`} data-aos-delay={i * 100} data-aos="fade-in">
                      {pathname === '/promotion' || pathname === '/promotionlanding' ? (
                        <HashLink smooth to={link.hash} className="menu-link">
                          {/* {link.title === 'Услуги' ? 'Тарифы' : link.title} */}
                          {link.title}
                        </HashLink>
                      ) : (
                        <NavLink onClick={link.onClick} to={`/${link.link}`} className="menu-link">
                          {link.title}
                        </NavLink>
                      )}
                    </li>
                  );
                }
                return false;
              })}
              <li className="menu-item"></li>
            </ul>
          </div>
          {!desktop ? (
            <button
              className="burger"
              data-aos="swipe-down"
              onClick={() => (isOpenedMenu ? toggleMenu(false) : toggleMenu(true))}
            >
              {!isOpenedMenu ? (
                <span
                  className={`burger-title ${desktop && 'data-aos-delay="500"'}`}
                  data-aos={desktop ? 'fade-in' : ''}
                >
                  
                  {isOpenedMenu 
                    ? 'Закрыть' 
                    : isIndexPage
                      ?<svg width="44" height="14" viewBox="0 0 44 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="44" height="3" fill="white"/>
                        <rect y="11" width="44" height="3" fill="white"/>
                      </svg>
                      : <svg width="44" height="14" viewBox="0 0 44 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="44" height="3" fill="#0A0D14"/>
                          <rect y="11" width="44" height="3" fill="#0A0D14"/>
                        </svg>
                      
                  }
                </span>
              ) : (
                <span className="burger__icon" />
              )}
            </button>
          ) : (
            <div className="menu-start" data-aos="swipe-down">
              {pathname === '/promotion' || pathname === '/promotionlanding' ? (
                <button type="button" className="menu-link" onClick={openModal}>
                  Начать проект
                </button>
              ) : (
                <div className="menu-right">
                  {/* <NavLink
                    to={`/brief`}
                    onClick={() => {
                      ReactGA.event({
                        category: 'appayer_sur_le_bouton',
                        action: 'aller_sur_une_autre_page',
                        label: 'aller_sur_une_autre_page_toit_de_site'
                      });
                      ym('reachGoal', 'startproject');
                    }}
                    className="menu-link"
                    data-aos-delay="500"
                    data-aos="fade-in"
                  >
                    Начать проект
                  </NavLink> */}
                  <NavLink
                    to={`/agency`}
                    className="menu-link"
                    data-aos-delay="500"
                    data-aos="fade-in"
                    onClick={() => {
                      ym('reachGoal', 'agency')
                    }}
                  >
                    Компания
                  </NavLink>
                  <NavLink
                    to={`/contacts`}
                    className="menu-link"
                    data-aos-delay="500"
                    data-aos="fade-in"
                    onClick={() => {
                      ym('reachGoal', 'contacts')
                    }}
                  >
                    Контакты
                  </NavLink>
                </div>
              )}
            </div>
          )}
        </div>
      </menu>
      <MainModal modalIsOpen={modalIsOpen} closeModal={closeModal} desktop={desktop} />
    </div>
  );
};

export default WithDeviceType(React.memo(Menu));
