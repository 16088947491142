import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { baseURL, partners } from '../../../../constants';
import Marquee from "react-fast-marquee";
import './scss/PartnersSection.scss';
import tavrida from '../../../../assets/images/parthners/tavrida.png';
import rvi from '../../../../assets/images/parthners/rvi.png';
import rubezh from '../../../../assets/images/parthners/rubezh.svg';
import torex from '../../../../assets/images/parthners/torex.svg';
import sawatzky from '../../../../assets/images/parthners/sawatzky.svg';

const PartnersSection = ({ desktop, tablet }) => {
    

    return (
        <section className="partners">
            <p className="label-text parthners-text">Клиенты</p>
                <div className="partners-slider">
                    <Marquee 
                        autoFill={true}
                        pauseOnHover={true}
                    >
                        <a href="projects/rubezh" target='_blank' className="partners-slider__item">
                            <img src={rubezh} alt="Rubezh" className='parthner-rubezh'/>
                        </a>
                        <a href="projects/tavrida-elektrik" target='_blank'  className="partners-slider__item">
                            <img src={tavrida} alt="Tavrida" className='parthner-tavrida'/>
                        </a>
                        <a href="projects/torex-premium" target='_blank'  className="partners-slider__item parthner-torex">
                            <img src={torex} alt="Torex"/>
                        </a>
                        <a href="projects/rvi" target='_blank'  className="partners-slider__item">
                            <img src={rvi} alt="Rvi" className='parthner-rvi'/>
                        </a>
                        <a href="https://asap-ag.ru/projects/sawatzky" target='_blank'  className="partners-slider__item parther-sawatzky">
                            <img src={sawatzky} alt="Sawatzky" className='parthner-sawatzky'/>
                        </a>
                    </Marquee>
                </div>
        </section>
    );
};

export default PartnersSection;
