import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperCore, { Navigation, EffectFade } from 'swiper';
import { formatLink, reinitSwiper } from '../functions/functions';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/effect-fade/effect-fade.min.css';
import { NavLink } from 'react-router-dom';
import './scss/ProjectsGallery.scss';
import WithDeviceType from '../HOCs/WithDeviceType';
import { projectsAPI } from '../../api';

const ProjectsGallery = ({ mobile, desktop }) => {
  let [projects, setProjects] = useState([]);
  const slideRef = useRef(null);

  SwiperCore.use([EffectFade, Navigation]);

  useEffect(() => {
    projectsAPI.getProjectsToSlider().then((res) => {
      setProjects(res.items);
    });
  }, []);

  // useEffect(() => {
  //   projectsAPI.getMainProjects().then((res) => {
  //     setProjects(res.items);
  //   });
  // }, []);

  const initNav = (swiper) => {
    swiper.navigation.prevEl.innerHTML = `<svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.7618 39L-6.45189e-06 19.5L19.7618 2.30769e-06L23.7142 3.9L10.6995 16.7423L37 17.0098L37 22.5252L10.6995 22.2577L23.7142 35.1L19.7618 39Z" fill="url(#paint0_radial)"/>
                                                <defs>
                                                <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.6814 39) rotate(-132.095) scale(50.2437 72.0712)">
                                                <stop offset="0.0272715" stop-color="#FA3D33"/>
                                                <stop offset="1" stop-color="#EE8B52"/>
                                                </radialGradient>
                                                </defs>
                                                </svg>
                                                `;
    swiper.navigation.nextEl.innerHTML = `<svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.2382 3.01402e-06L37 19.5L17.2382 39L13.2858 35.1L26.3005 22.2577L-7.55217e-06 21.9902L-5.65799e-06 16.4748L26.3005 16.7423L13.2858 3.9L17.2382 3.01402e-06Z" fill="url(#paint0_radial)"/>
                                                <defs>
                                                <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.31856) rotate(47.9051) scale(50.2437 72.0712)">
                                                <stop offset="0.0272715" stop-color="#FA3D33"/>
                                                <stop offset="1" stop-color="#EE8B52"/>
                                                </radialGradient>
                                                </defs>
                                                </svg>
                                                `;
  };

  return (
    <section className="services-gallery">
      <div className="wrapper">
        {desktop ? (
          <>
            <div className="services-gallery__table" data-aos-delay="400" data-aos="swipe-left">
              <div
                className="services-gallery__services"
                data-aos-delay="200"
                data-aos="swipe-down"
              >
                {/* <span className="services-gallery__title">Проекты</span> */}
                <Swiper
                  slidesPerView={1}
                  spaceBetween={25}
                  navigation
                  loop
                  effect="fade"
                  onSwiper={(swiper) => {
                    // reinitSwiper(swiper);
                    initNav(swiper);
                  }}
                  speed={450}
                  className="services-gallery__slider"
                  initialSlide={1}
                > 
                  {projects?.map((item, index) => {
                    return item.link == window.location.pathname.replace('/projects/', '')
                    ?(
                      null
                    )
                    : (
                      <SwiperSlide key={index} ref={slideRef}>
                        <div className="services-gallery__service">
                          <div className="services-gallery__service-info">
                            <span className="services-gallery__title title">Следующий проект</span>
                            <span className="services-gallery__service-title">{item.title}</span>
                            <div className="projects-gallery__project-tags">
                              {item.tags.map((tag, i) => (
                                <span key={i} className="projects-gallery__project-tag">
                                  {tag}
                                </span>
                              ))}
                              {item.types.map((tag) => (
                                <span key={tag.id} className="projects-gallery__project-type">
                                  {tag}
                                </span>
                              ))}
                            </div>
                            <a
                              href={item.website}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="services-gallery__link"
                            >
                              {formatLink(item.website)}
                            </a>
                          </div>

                          <NavLink
                            to={{
                              pathname: `/projects/${item.link}`,
                              link: item.link
                            }}
                            className="services-gallery__project"
                          >
                            <img
                              src={item.pic}
                              alt={item.title}
                              className="services-gallery__project-pic"
                            />
                          </NavLink>
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
            </div>
          </>
        ) : (
          <div className="services-gallery__table">
            <div className="services-gallery__slider">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                initialSlide={1}
                navigation
                loop
                effect="fade"
                modules={[EffectFade]}
                autoHeight={true}
                speed={450}
                onSwiper={(swiper) => {
                  // reinitSwiper(swiper);
                  initNav(swiper);
                }}
                className="services-gallery__slider"
              >
                {projects?.map((item, index) => {
                  return item.link == window.location.pathname.replace('/projects/', '')
                  ? (
                    null
                  )
                  : (
                    <SwiperSlide ref={slideRef} key={index}>
                      <NavLink
                        to={{
                          pathname: `/projects/${item.link}`,
                          link: `${item.link}`
                        }}
                      >
                        <span className="services-gallery__title title">Следующий проект</span>
                        <span className="services-gallery__service-title title">{item.title}</span>
                        <div className="projects-gallery__project-tags">
                          {item.tags.map((tag, i) => (
                            <span key={i} className="projects-gallery__project-tag">
                              {tag}
                            </span>
                          ))}
                          {item.types.map((tag) => (
                            <span key={tag.id} className="projects-gallery__project-type">
                              {tag}
                            </span>
                          ))}
                        </div>
                        <div className="services-gallery__pic-container">
                          <img src={item.pic} alt={item.title} className="services-gallery__pic" />
                          {/* TODO ANIMATION */}
                        </div>
                      </NavLink>
                      <a
                        href={item.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="services__gallery-link"
                      >
                        {formatLink(item.website)}
                      </a>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default WithDeviceType(ProjectsGallery);
