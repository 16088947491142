import React from "react";
import PurpleTitle from "../../../Common/PurpleTitle";
import Orbit from "../../../Common/Orbit";
import {agencyFacts} from "../../../../constants";
import WithDeviceType from "../../../HOCs/WithDeviceType";
import FactsTable from "../../../Common/FactsTable";

const AgencyStructure = ({windowWidth, mainRef}) => {
    return (
        <section className="section structure">
            <div className="wrapper wrapper_structure">
                <PurpleTitle title="ASAP - IT-интегратор"/>
                <div className="structure__info">
                    <div className="structure__content">
                        <span className="structure__content-title title" data-aos="fade-up">Продуктовый подход</span>
                        <p className="structure__content-text" data-aos="fade-up" data-aos-delay="100">
                            Технологические партнеры с&nbsp;отраслевой экспертизой, а&nbsp;не&nbsp;просто подрядчики.
                        </p>
                    </div>
                    {windowWidth > 670 && <Orbit facts={agencyFacts} stopped={true} mainRef={mainRef}/>}
                    {windowWidth <= 670 && <FactsTable facts={agencyFacts}/>}
                    <div className="structure__subtext" data-aos="fade-up">
                        <p>
                            Гармонично сочетаем опыт и&nbsp;энергию. Руководители подразделений имеют опыт работы в&nbsp;предметных областях от&nbsp;5&nbsp;до&nbsp;15&nbsp;лет.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default WithDeviceType(AgencyStructure);
