import React, { useEffect, useState } from 'react';
import ContactsSection from '../MainPage/MainPageSections/ContactsSection';
import WithDeviceType from '../../HOCs/WithDeviceType';
import Helmet from 'react-helmet';
import { useAppContext } from '../../../AppContext';
import WithTransition from '../../HOCs/WithTransition';
import '../MainPage/MainPage.scss';
import { NavLink, useHistory } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import TextSection from '../MainPage/MainPageSections/TextSection';
import SocialSection from '../MainPage/MainPageSections/SocialSection';
import BlackButton from '../../Common/BlackButton/BlackButton';
import TitleSection from '../../TitleSection/TitleSection';
import './ProizvodstvoPage.scss';
import FactsSlider from '../../FactsSlider/FactsSlider';
import ExpertizaSection from '../MainPage/MainPageSections/ExpertizaSection';
import { useDeviceType } from '../../../hooks';

import tavrida from '../../../assets/images/projects/newProjects/tavrida.png';
import shoemark from '../../../assets/images/projects/newProjects/shoemark.png';
import rubezh from '../../../assets/images/projects/newProjects/rubezh.png';
import rvi from '../../../assets/images/projects/newProjects/rvi.png';
import himark from '../../../assets/images/projects/newProjects/big-himark.png';

const ProizvodstvoPage = ({ isIndexPage, setIsIndexPage }) => {

    const { initPage, mainRef } = useAppContext();
    const projectsFirst = [
        {
            "id": 150,
            "title": "Таврида Электрик",
            "tags": [
                "Мобильное приложение",
            ],
            "types": [
                "PromTech"
            ],
            "link": "tavrida-elektrik",
            "pic": tavrida,
            "description": "Мобильное приложение для управление реклоузером без интернета с интеграцией по проприетарному протоколу.",
        },
        {
            "id": 151,
            "title": "RVI",
            "tags": [
                "Интернет-магазин",
            ],
            "types": [
                "E-commerce & Retail",
                "PromTech"
            ],
            "link": "rvi",
            "pic": rvi,
            "description": "Интернет-магазин одного из лидеров систем безопасности в России и СНГ.",
            "website": "https://rvigroup.ru"
        },
        {
            "id": 152,
            "title": "Rubezh",
            "tags": [
                "Информационный портал",
            ],
            "types": [
                "PromTech"
            ],
            "link": "rubezh",
            "pic": rubezh,
            "description": "Портал проектировщика - база полезных материалов и инструментов для работы с оборудованием производителя.",
            "website": "https://projects.rubezh.ru"
        },
        {
            "id": 250,
            "title": "Hi mark",
            "tags": [

            ],
            "types": [
                "PromTech"
            ],
            "pic": himark,
            "description": 'Программный комплекс для автоматизации обязательной маркировки "Честный знак" на производствах',
            "website": "https://up-line.online/#rec496292031"
        },
        {
            "id": 153,
            "title": "Shoes Mark",
            "tags": [
                "Web-сервис",
                "Мобильное приложение"
            ],
            "types": [
                "PromTech"
            ],
            "pic": shoemark,
            "description": 'Автоматизация обязательной маркировки товаров легкой промышленности. Интеграция с сервисами “Честный знак”, интеграция с принтером этикеток.',
            "link": "shoes-mark"
        },
    ]

    const { mobile } = useDeviceType();
    const history = useHistory();

    const [isProjectsPage] = useState(history.location.pathname === '/projects');

    const getImgSrc = (index, item) => {
        if (isProjectsPage) {
            return index !== 0 && (index + 1) % 3 === 0 ? item.mainPic : item.pic;
        }
        return item.pic;
    };
    useEffect(() => {
        initPage();
    }, []);

    return (
        <div className='proizvodstvo'>
            <Helmet>
                <title>PromTech</title>
                <meta
                    name="description"
                    content="Digital-решения для амбициозных компаний. Разработка сайтов и цифровых продуктов. Комплексный интернет-маркетинг."
                />

            </Helmet>
            <TitleSection textTitle='PromTech' textContent={`
                Запустили более 10 проектов для производственных компаний с 2019 года.
                <br/>
                C 2020 развиваем собственный продукт в данной отрасли.
                <br/>
                Умеем делать интеграции с любым промышленным оборудованием.
            `}>
            </TitleSection>
            <ExpertizaSection
                titleText="Проекты"
            >
                <div className="grid-cards">
                    {projectsFirst.map((item, i) => {
                        return (
                            item.id == 250
                                ? <a
                                    key={i}
                                    href={item.website}
                                    target='_blank'
                                    className="projects-gallery__project"
                                >
                                    <div className="projects-gallery__project-pic">
                                        {mobile ? (
                                            <div className="projects-gallery__project-pic-wrapper">
                                                <img
                                                    src={getImgSrc(i, item)}
                                                    alt={item.title}
                                                    className="projects-gallery__project-img"
                                                />
                                            </div>
                                        ) : (
                                            <img
                                                src={getImgSrc(i, item)}
                                                alt={item.title}
                                                className="projects-gallery__project-img"
                                            />
                                        )}
                                    </div>

                                    <div className="projects-gallery__project-info">
                                        <span className="projects-gallery__project-title">{item.title}</span>
                                        <p className="label-text projects-gallery__text">
                                            {item.description}
                                        </p>
                                        {!mobile && (
                                            <div className="projects-gallery__project-tags">
                                                {item.types.map((tag, i) => (
                                                    <span key={i} className="projects-gallery__project-type">
                                                        {tag}
                                                    </span>
                                                ))}
                                                {item.tags.map((tag, i) => (
                                                    <span key={i} className="projects-gallery__project-tag">
                                                        {tag}
                                                    </span>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                    {mobile && (
                                        <div className="projects-gallery__project-tags">
                                            {item.types.map((tag, i) => (
                                                <span key={i} className="projects-gallery__project-type">
                                                    {tag}
                                                </span>
                                            ))}
                                            {item.tags.map((tag, i) => (
                                                <span key={i} className="projects-gallery__project-tag">
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                </a>

                                : <NavLink
                                    key={i}
                                    to={{
                                        pathname: `/projects/${item.link}`,
                                        state: {
                                            link: item.link
                                        }
                                    }}
                                    className="projects-gallery__project"
                                >
                                    <div className="projects-gallery__project-pic">
                                        {mobile ? (
                                            <div className="projects-gallery__project-pic-wrapper">
                                                <img
                                                    src={getImgSrc(i, item)}
                                                    alt={item.title}
                                                    className="projects-gallery__project-img"
                                                />
                                            </div>
                                        ) : (
                                            <img
                                                src={getImgSrc(i, item)}
                                                alt={item.title}
                                                className="projects-gallery__project-img"
                                            />
                                        )}
                                    </div>

                                    <div className="projects-gallery__project-info">
                                        <span className="projects-gallery__project-title">{item.title}</span>
                                        <p className="label-text projects-gallery__text">
                                            {item.description}
                                        </p>
                                        {!mobile && (
                                            <div className="projects-gallery__project-tags">
                                                {item.types.map((tag, i) => (
                                                    <span key={i} className="projects-gallery__project-type">
                                                        {tag}
                                                    </span>
                                                ))}
                                                {item.tags.map((tag, i) => (
                                                    <span key={i} className="projects-gallery__project-tag">
                                                        {tag}
                                                    </span>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                    {mobile && (
                                        <div className="projects-gallery__project-tags">
                                            {item.types.map((tag, i) => (
                                                <span key={i} className="projects-gallery__project-type">
                                                    {tag}
                                                </span>
                                            ))}
                                            {item.tags.map((tag, i) => (
                                                <span key={i} className="projects-gallery__project-tag">
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                </NavLink>
                        );
                    })}
                </div>
            </ExpertizaSection>
            <TextSection>
                Корпоративное ПО, интеграции с оборудованием и внешними сервисами, сайты промышленных компаний.
                <br />
                <br />
                Автоматизация производственных процессов за счёт IT-инструментов.
            </TextSection>
            <SocialSection
                titleText="Цитата"
                personText="Для наших клиентов мы являемся технологическими партнерами с отраслевой экспертизой, а не просто подрядчиками."
            >
                <NavLink
                    to={`/brief`}
                    onClick={() => {
                        ReactGA.event({
                            category: 'appayer_sur_le_bouton',
                            action: 'aller_sur_une_autre_page',
                            label: 'aller_sur_une_autre_page_toit_de_site'
                        });
                        ym('reachGoal', 'startproject');
                    }}
                    className=""
                >
                    <BlackButton>Запустим продукт вместе</BlackButton>
                </NavLink>
            </SocialSection>
            <ContactsSection />
        </div>
    )
}

export default WithTransition(WithDeviceType(ProizvodstvoPage))