import React, { useState, useEffect } from 'react';
import {Link, NavLink} from 'react-router-dom';
import PurpleTitle from '../../Common/PurpleTitle';
import Helmet from 'react-helmet';
import WithDeviceType from '../../HOCs/WithDeviceType';
import ContactsSection from '../MainPage/MainPageSections/ContactsSection';
import { useAppContext } from '../../../AppContext';
import { promoDataAPI } from '../../../api';
import ToTopBtn from '../../Common/ToTopBtn';
import './PapersPage.scss';

const PapersPage = ({ desktop, style, children }) => {
    const { initPage } = useAppContext();

    useEffect(() => {
        initPage();
    }, []);

    const dataAPI = new promoDataAPI('razrabotkaim.json');
    const [data, setData] = useState([]);
    useEffect(() => {
        dataAPI.sendInfo('reviews').then((res) => setData(res));
    }, []);
  
  return (
    <>
        <Helmet>
            <title>Статьи</title>
            <meta name="description" content="Papers component" />
        </Helmet>
   
        <section className="papers" style={style}>
        <div className="wrapper">
            <PurpleTitle title="Статьи" />
            <div className="papers-list">
                <div className="papers-item">
                <div className="papers-header">
                    <p className="papers-header__text">Про сочетание эмпатии и авторитета, визуальный опыт и когда человек немного «подгорает»</p>
                    <div className="papers-header__image">
                        <img alt="vc.ru" src={require('../../../assets/images/papers/vc-ru.svg')} />
                    </div>
                </div>
                <div className="papers-main">
                    <div className="papers-autor">
                    <div className="papers-main__image">
                        <img alt="vc.ru" src={require('../../../assets/images/papers/pavel.png')} />
                    </div>
                    <div className="papers-autor__bio">
                        <p className="papers-autor__name">Павел - <span className='papers-autor__light'>Арт-Директор</span></p>
                        <span className='papers-autor__light'>11 июля 2022</span>
                    </div>
                    </div>
                    <a href={'https://vc.ru/hr/457629-pro-sochetanie-empatii-i-avtoriteta-vizualnyy-opyt-i-kogda-chelovek-nemnogo-podgoraet'} className="papers-autor__original" target="_blank">
                    подробнее
                    </a>
                </div>
                </div>
            
                <div className="papers-item">
                <div className="papers-header">
                    <p className="papers-header__text">Кейс о разработке интернет-магазина для крупнейшей сети строительных магазинов Саратовской области — Строй-С</p>
                    <div className="papers-header__image">
                        <img alt="vc.ru" src={require('../../../assets/images/papers/vc-ru.svg')} />
                    </div>
                </div>
                <div className="papers-main">
                    <div className="papers-autor">
                    <div className="papers-main__image">
                        <img alt="vc.ru" src={require('../../../assets/images/papers/daniil.png')} />
                    </div>
                    <div className="papers-autor__bio">
                        <p className="papers-autor__name">Даниил - <span className='papers-autor__light'>Директор</span></p>
                        <span className='papers-autor__light'>11 июля 2022</span>
                    </div>
                    </div>
                    <a href={'https://vc.ru/u/582595-asap/366484-keys-o-razrabotke-internet-magazina-dlya-krupneyshey-seti-stroitelnyh-magazinov-saratovskoy-oblasti-stroy-s'} className="papers-autor__original" target="_blank">
                    подробнее
                    </a>
                </div>
                </div>
            </div>
        </div>
        </section>
        <ContactsSection />
        {/* <ToTopBtn /> */}
    </>
  );
};

export default WithDeviceType(PapersPage);
