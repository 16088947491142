import React from "react";
import {mobileWidth, tabletWidth} from "../../constants";

const WithDeviceType = Component => props => {
    let desktop = true, mobile = false, tablet = false;
    let windowWidth = document.documentElement.offsetWidth;

    if (document.documentElement.offsetWidth <= tabletWidth && document.documentElement.offsetWidth > mobileWidth) {
        tablet = true;
        desktop = false;
    }

    if (document.documentElement.offsetWidth <= mobileWidth) {
        mobile = true;
        desktop = false;
    }

    return <Component desktop={desktop} tablet={tablet} mobile={mobile} windowWidth={windowWidth} {...props} />;
};

export default WithDeviceType;
