import React from 'react';
import './TitleSection.scss';
import BlackButton from '../Common/BlackButton/BlackButton';
import { NavLink } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';

const TitleSection = ({textTitle, textContent, children, ...props}) => {
  return (
    <section className="title-section">
        <div className="wrapper title-section__wrapper">
            <h1 className="title-section__title">{textTitle}</h1>
            <div className="title-section-right">
                <p className="title-section__text body-xl" dangerouslySetInnerHTML={{ __html: textContent }}></p>
                {children}
                <NavLink
                    to={`/brief`}
                    onClick={() => {
                    ReactGA.event({
                        category: 'appayer_sur_le_bouton',
                        action: 'aller_sur_une_autre_page',
                        label: 'aller_sur_une_autre_page_toit_de_site'
                    });
                    ym('reachGoal', 'startproject');
                    }}
                    className=""
                >
                    <BlackButton>Запустим продукт вместе</BlackButton>
                </NavLink>
            </div>
        </div>
    </section>
  )
}

export default TitleSection