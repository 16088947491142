import React from 'react';
import { NavLink } from 'react-router-dom';
import './scss/MobileMenu.scss';
import mobileBg from '../../assets/images/mobile-header.png';
import {
  email,
  fb,
  fbIcon,
  inst,
  instIcon,
  phone,
  vk,
  vkIcon,
  telegram
} from '../../constants';
import ym from 'react-yandex-metrika';

const MobileMenu = ({ links }) => {
  return (
    <div className="mobile-menu">
      <img src={mobileBg} className='mobile-menu-bg' alt="" />
      <ul className="mobile-menu__list">
        {console.log(links)}
        {links.map((link, index) => {
          if(index !== 0){
            return (
              <li key={index} className="mobile-menu__item">
                <NavLink
                  to={{
                    pathname: `/${link.link}`
                  }}
                  className="mobile-menu__link"
                >
                  {link.title}
                </NavLink>
              </li>
            );
          }
        })}
        <li className="mobile-menu__item">
          <NavLink
            to={{
              pathname: `/agency`
            }}
            className="mobile-menu__link"
          >
            Компания
          </NavLink>
        </li>
        <li className="mobile-menu__item">
          <NavLink
            to={{
              pathname: `/contacts`
            }}
            className="mobile-menu__link"
          >
            Контакты
          </NavLink>
        </li>
      </ul>
      <div className="mobile-menu-footer">
          <div className="mobile-menu__soc">
            <a
              href={telegram}
              onClick={() => ym('reachGoal', 'telegram')}
              target="_blank"
              rel="noopener noreferrer"
              className="mobile-menu__soc-icon mobile-menu__soc-icon_vk"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path d="M15 1.875C7.75123 1.875 1.87498 7.75125 1.87498 15C1.87498 22.2487 7.75123 28.125 15 28.125C22.2487 28.125 28.125 22.2487 28.125 15C28.125 7.75125 22.2487 1.875 15 1.875ZM21.5559 10.2C21.3478 12.3844 20.4487 17.6841 19.9912 20.13C19.7972 21.165 19.4165 21.5119 19.0472 21.5456C18.2447 21.6197 17.6362 21.0159 16.859 20.5059C15.6431 19.7091 14.9569 19.2131 13.7765 18.435C12.4125 17.5359 13.2965 17.0428 14.0737 16.2356C14.2772 16.0247 17.8115 12.81 17.88 12.5184C17.8884 12.4819 17.8969 12.3459 17.8153 12.2747C17.7347 12.2025 17.6156 12.2278 17.5294 12.2466C17.4075 12.2747 15.465 13.5581 11.7037 16.0978C11.1525 16.4766 10.6528 16.6603 10.2056 16.6509C9.71248 16.6406 8.76373 16.3725 8.05873 16.1428C7.19342 15.8616 6.50623 15.7134 6.56623 15.2353C6.59717 14.9869 6.9403 14.7319 7.59373 14.4722C11.6203 12.7181 14.3053 11.5612 15.6487 11.0025C19.484 9.40687 20.2819 9.13031 20.8012 9.12094C20.9156 9.11906 21.1706 9.14719 21.3365 9.28125C21.4762 9.39469 21.5147 9.5475 21.5334 9.65531C21.5522 9.76312 21.5747 10.0078 21.5569 10.1991L21.5559 10.2Z" fill="white"/>
              </svg>
            </a>
            <a
              href={vk}
              onClick={() => ym('reachGoal', 'vkontakte')}
              target="_blank"
              rel="noopener noreferrer"
              className="mobile-menu__soc-icon mobile-menu__soc-icon_vk"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path d="M15.5769 0.79541C7.61118 0.79541 1.15385 7.25274 1.15385 15.2185C1.15385 23.1842 7.61118 29.6416 15.5769 29.6416C23.5427 29.6416 30 23.1842 30 15.2185C30 7.25274 23.5427 0.79541 15.5769 0.79541ZM21.1238 17.0679C21.1238 17.0679 22.3993 18.327 22.7133 18.9114C22.7224 18.9234 22.7269 18.9354 22.7299 18.9414C22.8576 19.1563 22.8876 19.3231 22.8245 19.4478C22.7194 19.6551 22.3588 19.7572 22.2356 19.7663H19.982C19.8257 19.7663 19.4982 19.7257 19.1016 19.4523C18.7966 19.2389 18.4961 18.8889 18.2031 18.5478C17.7659 18.04 17.3873 17.6013 17.0057 17.6013C16.9572 17.6012 16.9091 17.6088 16.863 17.6238C16.5745 17.717 16.2049 18.1286 16.2049 19.2254C16.2049 19.5679 15.9345 19.7648 15.7437 19.7648H14.7115C14.36 19.7648 12.5285 19.6416 10.9059 17.9303C8.91977 15.8345 7.13191 11.6307 7.11689 11.5917C7.00421 11.3197 7.23708 11.174 7.49099 11.174H9.76713C10.0706 11.174 10.1698 11.3588 10.2389 11.5226C10.32 11.7134 10.6175 12.4721 11.1058 13.3255C11.8975 14.7167 12.3828 15.2816 12.7719 15.2816C12.8449 15.2807 12.9166 15.2622 12.9808 15.2275C13.4886 14.945 13.3939 13.1347 13.3714 12.7591C13.3714 12.6884 13.3699 11.9493 13.11 11.5947C12.9237 11.3378 12.6067 11.2401 12.4144 11.2041C12.4922 11.0967 12.5947 11.0096 12.7133 10.9502C13.0619 10.7759 13.6899 10.7503 14.3134 10.7503H14.6605C15.3365 10.7594 15.5108 10.8029 15.7557 10.8645C16.2515 10.9832 16.262 11.3032 16.2184 12.3985C16.2049 12.7095 16.1914 13.061 16.1914 13.4757C16.1914 13.5658 16.1869 13.662 16.1869 13.7642C16.1719 14.3216 16.1538 14.9541 16.5475 15.214C16.5988 15.2462 16.6581 15.2633 16.7188 15.2636C16.8555 15.2636 17.2671 15.2636 18.3819 13.351C18.7257 12.7354 19.0245 12.0957 19.2758 11.4369C19.2984 11.3979 19.3645 11.2777 19.4426 11.2311C19.5003 11.2017 19.5642 11.1868 19.6289 11.1875H22.3047C22.5962 11.1875 22.796 11.2311 22.8335 11.3438C22.8996 11.5226 22.8215 12.0679 21.6001 13.7221L21.0547 14.4417C19.9474 15.8931 19.9474 15.9667 21.1238 17.0679Z" fill="white"/>
              </svg>
            </a>
          </div>
          <NavLink
            to={{
              pathname: `/${links[0].link}`
            }}
            className="mobile-menu-footer__link"
          >
              {links[0].title}
          </NavLink>
        </div>
    </div>
  );
};

export default MobileMenu;
