import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

import WithDeviceType from '../../HOCs/WithDeviceType';
import './index.scss';

const FactsList = ({ api, style }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    api.sendInfo('approach').then((res) => setData(res));
  }, []);

  return (
    <div className="wrapper factslist" style={style}>
      <h2 className="factsList__title" data-aos="fade-up">
        {ReactHtmlParser(data?.title)}
      </h2>
      <span className="factsList__subtitle">{ReactHtmlParser(data?.subtitle)}</span>

      {data?.list?.map((item, index) => (
        <div className="factsList__row" key={`factsList--${index}`} data-aos="fade-up">
          <h4>{ReactHtmlParser(item.number)}</h4>
          <h4>{ReactHtmlParser(item.title)}</h4>
          <p>{ReactHtmlParser(item.text)}</p>
        </div>
      ))}
    </div>
  );
};

export default WithDeviceType(FactsList);
