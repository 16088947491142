import React from 'react';
import { NavLink } from 'react-router-dom';
import './index.scss';

export const ServicesBlockItem = (props) => {
  const { service, ...other } = props;
  return (
    <NavLink
      {...other}
      to={`/services/${service.link}`}
      className="servicesBlockItem"
      data-aos="fade-up"
    >
      <span className="servicesBlockItem__text">{service.title}</span>
      <span className="servicesBlockItem__icon">
        <svg
          width="27"
          height="15"
          viewBox="0 0 27 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd" 
            clipRule="evenodd"
            d="M20.0871 0.298306L26.6958 6.77983C27.1014 7.17757 27.1014 7.82243 26.6958 8.22017L20.0871 14.7017C19.6816 15.0994 19.0241 15.0994 18.6185 14.7017C18.213 14.304 18.213 13.6591 18.6185 13.2614L23.4545 8.51847L-7.59111e-07 8.51847L-5.77592e-07 6.48153L23.4545 6.48153L18.6185 1.73864C18.213 1.34091 18.213 0.696044 18.6185 0.298306C19.0241 -0.0994331 19.6816 -0.099433 20.0871 0.298306Z"
            fill="white"
          />
        </svg>
      </span>
    </NavLink>
  );
};
