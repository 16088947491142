import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import Modal from 'react-modal';

import WithDeviceType from '../../HOCs/WithDeviceType';
import Alert from '../../Common/Alert';
import { promoAPI } from '../../../api';
import { useScroll } from '../../../hooks';
import ReactHtmlParser from 'react-html-parser';
import './index.scss';

const Outro = ({ desktop, style, title }) => {
  const history = useHistory();
  const modalStyles = {
    overlay: {
      zIndex: 10,
      background: 'rgba(0,0,0,0.5)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: desktop ? '37.5rem' : '100%',
      height: desktop ? 'auto' : '100vh',
      backgroundColor: '#fff',
      padding: '2.4375rem 3.375rem 2.875rem 2.6875rem'
    }
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = (type) => {
    setIsOpen(true);
    setType(type);
  };
  function closeModal() {
    setIsOpen(false);
  }

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState(new Set());

  const [type, setType] = useState('');

  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({});

  const refName = useRef(null);
  const refPhone = useRef(null);
  const refComment = useRef(null);

  const clearForm = () => {
    setName('');
    setPhone('');
    setComment('');
    setErrors(new Set());
  };

  const validate = (inputName, inputValue) => {
    switch (inputName) {
      case 'phone':
        return !!inputValue.match(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){6,14}(\s*)?$/);
      default:
        return true;
    }
  };

  const validateForm = () => {
    const inputs = [refName.current, refPhone.current, refComment.current];

    let newErrors = new Set(errors);
    inputs.forEach((item) => {
      validate(item.name, item.value) ? newErrors.delete(item.name) : newErrors.add(item.name);
    });

    setErrors(newErrors);
    return newErrors.size;
  };

  const sendForm = (e) => {
    e.preventDefault();
    let errorsSize = validateForm();
    if (!errorsSize) {
      let formData = new FormData();
      !!name && formData.append('name', name);
      !!phone && formData.append('phone', phone);
      !!comment && formData.append('comment', comment);
      formData.append('type', type);

      promoAPI
        .sendAudit(formData)
        .then(() => {
          clearForm();
          closeModal();
          history.push('/success');
          ym('reachGoal', 'otpravit-zayavku');
          ReactPixel.track('SubmitApplication');
          ReactGA.event({
            category: 'appayer_sur_le_bouton',
            action: 'envoyer',
            label: 'envoyer_un_message_en_devenir_client'
          });
        })
        .catch(() => {
          closeModal();
          setAlertContent({
            title: '',
            isErrorAlert: true,
            text: 'Что-то пошло не так, попробуйте отправить заявку позже.',
            buttonText: 'Вернуться',
            onClose: () => setShowAlert(false)
          });
          setShowAlert(true);
        });
    }
  };

  const onInputChange = (e) => {
    let val = e.target.value;
    switch (e.target.name) {
      case 'phone':
        setPhone(val);
        break;
      case 'comment':
        setComment(val);
        break;
      default:
        setName(val);
    }
  };

  const ref = useRef(null);
  useScroll(ref);

  return (
    <section className="outro" id="outro" ref={ref}>
      {showAlert && <Alert {...alertContent} />}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={modalStyles}>
        <button type="button" className="outroModal__close" onClick={closeModal}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="-1"
              x2="24.8353"
              y2="-1"
              transform="matrix(0.68451 -0.729003 0.68451 0.729003 2 19.8599)"
              stroke="#CECECE"
              strokeWidth="2"
            />
            <line
              y1="-1"
              x2="24.8353"
              y2="-1"
              transform="matrix(-0.68451 -0.729003 -0.68451 0.729003 18 19.8599)"
              stroke="#CECECE"
              strokeWidth="2"
            />
          </svg>
        </button>
        <form>
          <h2 className="outroModal__title">Мы свяжемся с вами в течение 2-х часов</h2>
          <input
            ref={refName}
            onChange={onInputChange}
            value={name}
            type="text"
            name="name"
            className="outroModal__input"
            placeholder="Имя"
          />
          <input
            ref={refPhone}
            onChange={onInputChange}
            name="phone"
            value={phone}
            type="phone"
            className={`outroModal__input ${errors.has('phone') ? 'outroModal__input_error' : ''}`}
            placeholder="Телефон"
          />
          <textarea
            ref={refComment}
            onChange={onInputChange}
            name="comment"
            value={comment}
            className="outroModal__inputArea"
            placeholder="Кратко о компании и задачах"
          />
          <button type="submit" className="outroModal__submit" onClick={sendForm}>
            Отправить заявку
          </button>
        </form>
      </Modal>

      <div className="wrapper">
        <div className="contacs" data-aos="fade-up" style={style}>
          <h2 className="contacs__title" data-aos="fade-up">
            {ReactHtmlParser(title)}
          </h2>
          <button
            type="button"
            className="contacs__project"
            data-aos="fade-up"
            onClick={() => {
              ym('reachGoal', 'parler-du-projet');
              openModal('project');
              ReactGA.event({
                category: 'appayer_sur_le_bouton',
                action: 'ouvrir_la_fenetre',
                label: 'distuter_du_projet_bloc_inferieur'
              });
            }}
          >
            Обсудить проект
          </button>
        </div>
      </div>
    </section>
  );
};

export default WithDeviceType(Outro);
