import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperCore, { Navigation } from 'swiper';
import Modal from 'react-modal';
import ReactHtmlParser from 'react-html-parser';
import ReactPixel from 'react-facebook-pixel';
import ym from 'react-yandex-metrika';

import WithDeviceType from '../../HOCs/WithDeviceType';
import Alert from '../../Common/Alert';
import { reinitSwiper } from '../../functions/functions';
import { useScroll } from '../../../hooks';
import { promoAPI } from '../../../api';
import './index.scss';

const Team = ({ desktop, api, style, mainWorker }) => {
  const history = useHistory();
  const modalStyles = {
    overlay: {
      zIndex: 10,
      background: 'rgba(0,0,0,0.5)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: desktop ? '56.125rem' : '100%',
      height: desktop ? 'auto' : '100vh',
      backgroundColor: '#fff',
      padding: '2.4375rem 3.375rem 2.875rem 2.6875rem'
    }
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => {
    ym('reachGoal', 'napisat-sotrudnik');
    setIsOpen(true);
  };
  function closeModal() {
    setIsOpen(false);
  }

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState(new Set());
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({});
  const refName = useRef(null);
  const refPhone = useRef(null);
  const refEmail = useRef(null);
  const refComment = useRef(null);
  const clearForm = () => {
    setName('');
    setPhone('');
    setEmail('');
    setComment('');
    setErrors(new Set());
  };
  const validate = (inputName, inputValue) => {
    switch (inputName) {
      case 'phone':
        return !!inputValue.match(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){6,14}(\s*)?$/);
      default:
        return true;
    }
  };
  const validateForm = () => {
    const inputs = [refName.current, refPhone.current, refEmail.current, refComment.current];
    let newErrors = new Set(errors);
    inputs.forEach((item) => {
      validate(item.name, item.value) ? newErrors.delete(item.name) : newErrors.add(item.name);
    });
    setErrors(newErrors);
    return newErrors.size;
  };
  const sendForm = (e) => {
    e.preventDefault();
    let errorsSize = validateForm();
    if (!errorsSize) {
      let formData = new FormData();
      !!name && formData.append('name', name);
      !!phone && formData.append('phone', phone);
      !!email && formData.append('email', email);
      !!comment && formData.append('comment', comment);
      formData.append('type', 'traffic');

      promoAPI
        .sendAudit(formData)
        .then(() => {
          clearForm();
          closeModal();
          history.push('/success');
          setShowAlert(true);
          ReactPixel.track('SubmitApplication');
          ym('reachGoal', 'napisat-sotrudnik-otpravka');
        })
        .catch(() => {
          closeModal();
          setAlertContent({
            title: '',
            isErrorAlert: true,
            text: 'Что-то пошло не так, попробуйте отправить заявку позже.',
            buttonText: 'Вернуться',
            onClose: () => setShowAlert(false)
          });
          setShowAlert(true);
        });
    }
  };
  const onInputChange = (e) => {
    let val = e.target.value;
    switch (e.target.name) {
      case 'name':
        setName(val);
        break;
      case 'phone':
        setPhone(val);
        break;
      case 'email':
        setEmail(val);
        break;
      case 'comment':
        setComment(val);
        break;
      default:
        setName(val);
    }
  };

  SwiperCore.use([Navigation]);
  const initNav = (swiper) => {
    swiper.navigation.prevEl.innerHTML = `<svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.7618 39L-6.45189e-06 19.5L19.7618 2.30769e-06L23.7142 3.9L10.6995 16.7423L37 17.0098L37 22.5252L10.6995 22.2577L23.7142 35.1L19.7618 39Z" fill="url(#paint0_radial)"/>
                                                <defs>
                                                    <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.6814 39) rotate(-132.095) scale(50.2437 72.0712)">
                                                        <stop offset="0.0272715" stop-color="#FA3D33"/>
                                                        <stop offset="1" stop-color="#EE8B52"/>
                                                    </radialGradient>
                                                </defs>
                                            </svg>`;

    swiper.navigation.nextEl.innerHTML = `<svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.2382 3.01402e-06L37 19.5L17.2382 39L13.2858 35.1L26.3005 22.2577L-7.55217e-06 21.9902L-5.65799e-06 16.4748L26.3005 16.7423L13.2858 3.9L17.2382 3.01402e-06Z" fill="url(#paint0_radial)"/>
                                                <defs>
                                                <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.31856) rotate(47.9051) scale(50.2437 72.0712)">
                                                <stop offset="0.0272715" stop-color="#FA3D33"/>
                                                <stop offset="1" stop-color="#EE8B52"/>
                                                </radialGradient>
                                                </defs>
                                                </svg>
                                                `;
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    api.sendInfo('team').then((res) => setData(res));
  }, []);

  const ref = useRef(null);
  useScroll(ref);
  return (
    <section className="section promotionTeam" id="team" ref={ref} style={style}>
      {showAlert && <Alert {...alertContent} />}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={modalStyles}>
        <button className="teamModal__close" onClick={closeModal}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="-1"
              x2="24.8353"
              y2="-1"
              transform="matrix(0.68451 -0.729003 0.68451 0.729003 2 19.8599)"
              stroke="#CECECE"
              strokeWidth="2"
            />
            <line
              y1="-1"
              x2="24.8353"
              y2="-1"
              transform="matrix(-0.68451 -0.729003 -0.68451 0.729003 18 19.8599)"
              stroke="#CECECE"
              strokeWidth="2"
            />
          </svg>
        </button>

        <h2 className="teamModal__title">{ReactHtmlParser(data?.modalText)}</h2>
        <div className="teamModal__wrapper">
          <div className="teamModal__picture">
            <img src={mainWorker} alt="worker" />
          </div>
          <div className="teamModal__info">
            <input
              className="teamModal__input"
              placeholder="Имя"
              ref={refName}
              onChange={onInputChange}
              value={name}
              type="text"
              name="name"
            />
            <input
              type="text"
              className={`teamModal__input ${errors.has('phone') ? 'teamModal__input_error' : ''}`}
              placeholder="Телефон"
              ref={refPhone}
              onChange={onInputChange}
              value={phone}
              name="phone"
            />
            <input
              type="text"
              className="teamModal__input"
              placeholder="Email"
              ref={refEmail}
              onChange={onInputChange}
              value={email}
              name="email"
            />
            <textarea
              className="teamModal__inputArea"
              placeholder="Напишите вопрос"
              ref={refComment}
              onChange={onInputChange}
              value={comment}
              name="comment"
            />
            <button type="submit" className="teamModal__submit" onClick={sendForm}>
              Отправить
            </button>
            <p className="teamModal__text">
              Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь с
              политикой конфиденциальности.
            </p>
          </div>
        </div>
      </Modal>
      <div className="wrapper promotionTeam__wrapper">
        <div className="promotionTeam__header" data-aos="fade-up">
          <h2 className="promotionTeam__title">Команда вашего проекта</h2>
          <p className="promotionTeam__subtitle">{ReactHtmlParser(data?.desc)}</p>
        </div>
        <div className="promotionTeam__slider">
          <Swiper
            data-aos="fade-in"
            data-aos-delay="300"
            spaceBetween={20}
            navigation
            slidesPerView={desktop ? 4 : 1}
            onSwiper={(swiper) => {
              reinitSwiper(swiper);
              initNav(swiper);
            }}
          >
            {data?.workers?.map((item, i) => {
              return (
                <SwiperSlide
                  key={`team--${i}`}
                  className="promotionTeam__slide"
                  data-aos="fade-up"
                  data-aos-delay={(i + 1) * 100}
                >
                  <div className="promotionTeam__slide-container">
                    <img src={item.pic} alt={item.name} className="promotionTeam__slide-pic" />
                  </div>
                  <span className="promotionTeam__slide-name">{item.name}</span>
                  <span className="promotionTeam__slide-position">{item.position}</span>
                  <span className="promotionTeam__slide-desc">{ReactHtmlParser(item.desc)}</span>
                  {i === 0 && (
                    <button className="promotionTeam__slide-write" onClick={openModal}>
                      Написать
                    </button>
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        {/* {tablet && <NavLink to="/agency" className="promotionTeam__showMore">Подробнее о компании</NavLink>} */}
      </div>
    </section>
  );
};

export default WithDeviceType(Team);
