import React from 'react';
import { formatLink } from '../../functions/functions';
import WithDeviceType from '../../HOCs/WithDeviceType';
import { NavLink } from 'react-router-dom';
import { linkIcon } from '../../../constants';

const ProjectsList = ({
  desktop,
  projects,
  loadMore,
  curPage,
  totalPages,
  isLoading,
  tableHeight
}) => {
  return (
    <div className="projects-list__table" style={{ height: tableHeight }}>
      <div  className="projects-list__list">
        {projects.map((item, i) => {
          return (
              item.projects.map((project) => {
                return (
                  <span
                    key={project.id}
                    className="projects-list__item"
                    data-aos-delay={(i + 1) * 100}
                    data-aos="swipe-left"
                  >
                    {project.link ? (
                      <NavLink
                        to={{
                          pathname: `/projects/${project.link}`,
                          state: {
                            link: project.link
                          }
                        }}
                        className="projects-list__item-title"
                        data-aos="fade-in"
                        data-aos-delay={(i + 2) * 100}
                      >
                        {project.title}
                      </NavLink>
                    ) : (
                      <a
                        href={project.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="projects-list__item-title"
                        data-aos="fade-in"
                        data-aos-delay={(i + 2) * 100}
                      >
                        {project.title}
                      </a>
                    )}

                  {desktop ? (
                    <>
                      <div className="projects-list__item-tags">
                        {project.tags.map((tag, i) => {
                          return (
                            <span
                              key={tag.id}
                              className="projects-list__item-tag"
                              data-aos="fade-in"
                              data-aos-delay={(i + 4) * 100}
                            >
                              {tag}
                            </span>
                          );
                        })}
                      </div>
                      <div className="projects-list__item-types">
                          {project.types.map((tag, i) => {
                            return (
                              <span
                                key={tag.id}
                                className="projects-list__item-type"
                                data-aos="fade-in"
                                data-aos-delay={(i + 4) * 100}
                              >
                                {tag}
                              </span>
                            );
                          })}
                      </div>
                    </>)
                    : (
                      <div className="projects-list__item-tags">
                        {project.tags.map((tag, i) => {
                          return (
                            <span
                              key={tag.id}
                              className="projects-list__item-tag"
                              data-aos="fade-in"
                              data-aos-delay={(i + 4) * 100}
                            >
                              {tag}
                            </span>
                          );
                        })}
                        {project.types.map((tag, i) => {
                          return (
                            <span
                              key={tag.id}
                              className="projects-list__item-type"
                              data-aos="fade-in"
                              data-aos-delay={(i + 4) * 100}
                            >
                              {tag}
                            </span>
                          );
                        })}
                      </div>
                    )}
                    
                    {project.website ? (
                      <a
                        href={project.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="projects-list__item-link"
                        data-aos="fade-in"
                        data-aos-delay={(i + 3) * 100}
                      >
                        {linkIcon}
                        {formatLink(project.website)}
                      </a>
                    )
                    : (
                      <span className="projects-list__item-link"></span>
                    )
                  }
                    
                  </span>
                );
            })
          );
        })}
      </div>
    </div>
  );
};

export default WithDeviceType(ProjectsList);
