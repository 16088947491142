import React, { useState, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { useScroll } from '../../../hooks';
import './index.scss';

const Desc = ({ api, style, Title }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    api.sendInfo('promoDesc').then((res) => setData(res));
  }, []);

  const ref = useRef(null);
  useScroll(ref);
  return (
    <section className="desc" id="desc" ref={ref} style={style}>
      <div className="wrapper">
        <Title />
        <div className="skills" data-aos="fade-up">
          {data?.map((item, index) => (
            <div className="skill" data-aos="fade-up" key={`desc--${index}`}>
              <h4>{ReactHtmlParser(item.number)}</h4>
              <h4>{ReactHtmlParser(item.title)}</h4>
              <div className="skill__description">
                <p>{ReactHtmlParser(item.text)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Desc;
