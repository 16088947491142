import React from 'react';
import './index.scss';

export const LayoutWrapper = ({ children, ...other }) => {
  return (
    <div className="wrapper" {...other}>
      {children}
    </div>
  );
};
