import React, { useEffect, useState } from 'react';
import WithDeviceType from '../../../HOCs/WithDeviceType';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.scss';
import { reinitSwiper } from '../../../functions/functions';
import './scss/ArticlesSection.scss';
import { NavLink } from 'react-router-dom';
import { articlesAPI } from '../../../../api';

const Articles = ({ desktop, tablet, mobile }) => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    articlesAPI.getArticles().then((res) => setArticles(res));
  }, []);

  SwiperCore.use([Navigation]);

  const initNav = (swiper) => {
    swiper.navigation.prevEl.innerHTML = `<svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7618 39L-6.45189e-06 19.5L19.7618 2.30769e-06L23.7142 3.9L10.6995 16.7423L37 17.0098L37 22.5252L10.6995 22.2577L23.7142 35.1L19.7618 39Z" fill="url(#paint0_radial)"/>
    <defs>
        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.6814 39) rotate(-132.095) scale(50.2437 72.0712)">
            <stop offset="0.0272715" stop-color="#FA3D33"/>
            <stop offset="1" stop-color="#EE8B52"/>
        </radialGradient>
    </defs>
</svg>`;
    swiper.navigation.nextEl.innerHTML = `<svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.2382 3.01402e-06L37 19.5L17.2382 39L13.2858 35.1L26.3005 22.2577L-7.55217e-06 21.9902L-5.65799e-06 16.4748L26.3005 16.7423L13.2858 3.9L17.2382 3.01402e-06Z" fill="url(#paint0_radial)"/>
                                                <defs>
                                                <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.31856) rotate(47.9051) scale(50.2437 72.0712)">
                                                <stop offset="0.0272715" stop-color="#FA3D33"/>
                                                <stop offset="1" stop-color="#EE8B52"/>
                                                </radialGradient>
                                                </defs>
                                                </svg>
                                                `;
  };

  return (
    <section className="section main-articles">
      <div className="wrapper main-articles__wrapper">
        <div className="main-articles__title-container">
          <h2 className="main-articles__title title" data-aos="fade-up">
            Статьи
          </h2>
          {mobile && (
            <NavLink to="/articles" className="main-articles__showAll_mobile" data-aos="fade-up">
              Все статьи
            </NavLink>
          )}
        </div>
        {!mobile && (
          <div className="main-articles__slider">
            <Swiper
                    spaceBetween={mobile ? 20 : 100}
                    navigation
                    slidesPerView={"auto"}
                    onSwiper={(swiper) => {
                        reinitSwiper(swiper);
                        initNav(swiper);
                    }}
                >
                    {articles.map((item, i) => {
                        return <SwiperSlide key={i}
                                            data-aos="fade-up"
                                            data-aos-delay={(i + 1) * 100}
                                            className="main-articles__slide">
                            <div className="main-articles__slide-container">
                                <img src={item.pic} alt={item.title} className="main-articles__slide-pic"/>
                            </div>
                            <span className="main-articles__slide-title">{item.title}</span>
                        </SwiperSlide>
                    })}
                    {!mobile &&
                    <NavLink to="/articles" className="main-articles__showAll" data-aos="fade-up">Все статьи</NavLink>}
                </Swiper>
          </div>
        )}
        {mobile && (
          <div className="main-articles__list">
            {articles.map((item, i) => {
              return (
                <NavLink to={item.link} key={i} className="main-articles__slide">
                  <div className="main-articles__slide-container">
                    <img src={item.pic} alt={item.title} className="main-articles__slide-pic" />
                  </div>
                  <span className="main-articles__slide-title">{item.title}</span>
                </NavLink>
              );
            })}
            <button className="main-articles__showMore">Показать ещё</button>
          </div>
        )}
      </div>
    </section>
  );
};

export default WithDeviceType(Articles);
