import animateScrollTo from 'animated-scroll-to';
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Helmet from 'react-helmet';
import { useParams, useHistory, NavLink } from 'react-router-dom';
import { servicesAPI } from '../../../api';
import { useAppContext } from '../../../AppContext';
import { defaultScrollOptions, servicesFacts } from '../../../constants';
import FactsTable from '../../Common/FactsTable';
import Orbit from '../../Common/Orbit';
import PurpleTitle from '../../Common/PurpleTitle';
import WithDeviceType from '../../HOCs/WithDeviceType';
import WithTransition from '../../HOCs/WithTransition';
import ContactsSection from '../MainPage/MainPageSections/ContactsSection';
import ConsultationSection from '../MainPage/MainPageSections/СonsultationSection';
import ToTopBtn from '../../Common/ToTopBtn';
import './Services.scss';

const ServicesPage = ({ desktop, windowWidth }) => {
  const [services, setServices] = useState([]);
  const [currentSection, setCurrentSection] = useState(0);
  const [servicesElems, setServiceElems] = useState([]);
  let scrollPos = useRef(window.scrollY).current;
  const [scrollingToBottom, setScrollingToBottom] = useState(false);
  const history = useHistory();

  const { initPage, mainRef } = useAppContext();
  const URLParams = useParams();

  useEffect(() => {
    if (history.location.hash) {
      animateScrollTo(document.querySelector(history.location.hash), defaultScrollOptions);
    }
  }, []);

  useEffect(() => {
    initPage();

    servicesAPI.getServices().then((res) => {
      setServices(res);
      console.log(res)
    });

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (services.length) {
      setServiceElems(mainRef.current.querySelectorAll('.topic__item'));
    }
  }, [services]);
  useEffect(() => {
    if (servicesElems.length) {
      window.addEventListener('scroll', onScroll);
      if (URLParams.serviceName) {
        animateScrollTo(
          mainRef.current.querySelector(`#${URLParams.serviceName}`),
          defaultScrollOptions
        );
      }
    }
  }, [servicesElems]);

  const onScroll = (e) => {
    let newScrollPos = window.scrollY;
    if (newScrollPos > scrollPos) {
      setScrollingToBottom(true);
    } else {
      setScrollingToBottom(false);
    }
    for (let i = servicesElems.length - 1; i >= 0; i--) {
      let elem = servicesElems[i];

      if (elem.getBoundingClientRect().top <= 400) {
        setCurrentSection(i);
        break;
      }
    }
    scrollPos = newScrollPos;
  };

  const handleScrollToAnchor = (id) => () => {
    animateScrollTo(document.querySelector(id), defaultScrollOptions);
  };

  const setHashOnScroll = () => {};
  useEffect(() => {
    window.addEventListener('scroll', setHashOnScroll);
    return () => window.removeEventListener('scroll', setHashOnScroll);
  }, []);

  useEffect(() => {
    console.log(history, 'history');
  }, []);

  return (
    <>
      <Helmet>
        <title>Услуги</title>
        <meta name="description" content="Services component" />
      </Helmet>
      <section className="services">
        <div className="wrapper">
          <PurpleTitle title="Услуги" />
          <div className="services__box">
            <div className="services__info">
              <div className="services__text" data-aos="fade-up">
                Разрабатываем цифровые продукты <br /> с продуманным дизайном, технически сложным
                функционалом и внешними интеграциями. Сочетаем компетенции, опыт и технологии для
                создания эффективных IT-решений.
              </div>
              {windowWidth <= 670 && <FactsTable facts={servicesFacts} />}
            </div>
          </div>
          {windowWidth > 670 && <Orbit facts={servicesFacts} stopped={true} />}
        </div>
      </section>
      <section className="topic">
        <div className="wrapper">
          <div className="topic__box">
            <aside className="topic__aside">
              {!!services.length &&
                desktop &&
                services.map((item, i) => {
                  return (
                    <button
                      type="button"
                      key={`arnchor-${item.id}`}
                      onClick={handleScrollToAnchor(`#${item.link}`)}
                      className={clsx('topic__btn', i === currentSection && 'active')}
                    >
                      {item.title}
                    </button>
                  );
                })}
            </aside>
            <div className="topic__wrap">
              {!!services.length &&
                services.map((item, i) => {
                  return (
                    <div
                      className="topic__item"
                      key={`${item.link}-${item.id}`}
                      id={`${item.link}`}
                    >
                      <h2 className="topic__title">{item.title}</h2>
                      <div className="topic__text">{item.description}</div>
                      {/* TODO: Список технологий и галлерея изображений. Сказали пока не добавлять 10.09.21, 
                        возможно понадобится в будущем */}
                      {/* <div className="topic__techs">
                        <img src="." alt="" className="topic__techsIcon" />
                        <img src="." alt="" className="topic__techsIcon" />
                        <img src="." alt="" className="topic__techsIcon" />
                        <img src="." alt="" className="topic__techsIcon" />
                      </div> 
                      
                      {desktop && (
                        <div className="topic__gallery">
                          <a href="/" className="topic__galleryLink">
                            <img src={item.pic} alt="" className="topic__galleryImg" />
                          </a>
                          <a href="/" className="topic__galleryLink">
                            <img src="" alt="" className="topic__galleryImg" />
                          </a>
                          <a href="/" className="topic__galleryLink">
                            <img src="" alt="" className="topic__galleryImg" />
                          </a>
                        </div>
                      )}*/}

                      <div className="price">
                        {!!item.services.length &&
                          item.services.map((item, i) => {
                            return (
                              <div
                                key={`${item.title}-${item.price}`}
                                id={item.link}
                                className="price__item"
                              >
                                <div className="price__info">
                                  {item.link ? (
                                    <NavLink to={item.link} className="price__title price__link">
                                      {item.title}
                                    </NavLink>
                                  ) : (
                                    <h4 className="price__title">{item.title}</h4>
                                  )}

                                  <div className="price__params">{item.tech}</div>
                                </div>
                                <h4 className="price__value">{item.price}</h4>
                              </div>
                            );
                          })}
                      </div>
                      {/* TODO: Детальная страница в разработке, компонент ServicesItemPage */}
                      {/* <a href={item.link} className="topic__more">
                        Подробнее об услугах
                      </a> */}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
      <ConsultationSection/>
      <ContactsSection />
      {/* <ToTopBtn /> */}
    </>
  );
};

export default WithTransition(WithDeviceType(ServicesPage));
