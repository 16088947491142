import React, { useEffect, useState } from 'react';
import './scss/PurpleTitle.scss';
import { dragWords } from '../functions/functions';
import { useAppContext } from '../../AppContext';
import WithDeviceType from '../HOCs/WithDeviceType';
import { isSafari } from 'react-device-detect';

const PurpleTitle = ({ title, windowWidth }) => {
  const { mainRef } = useAppContext();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // if(isSafari) {
    //     dragWords('.purple-title__title-wrapper');
    // } else{
    //     isScrolledTitle();
    // }
    isScrolledTitle();
  }, []);

  const isScrolledTitle = () => {
    // if (mainRef.current.querySelector('.purple-title__title').offsetWidth > windowWidth) {
    //   setIsScrolled(true);
    // } else {
    //   setIsScrolled(false);
    // }
    setIsScrolled(true);
  };

  return (
    <div className="purple-title__title-box">
      <div className={`purple-title__title-wrapper purple-title__title-wrapper_safari`}>
        <>
          <span
            className={`purple-title__title purple-title__title_animated`}
            data-aos-delay="200"
            data-aos="fade-up"
          >{`${title}`}</span>
          <span
            className={`purple-title__title purple-title__title_animated`}
            data-aos-delay="200"
            data-aos="fade-up"
          >{`${title}`}</span>
          <span
            className={`purple-title__title purple-title__title_animated`}
            data-aos-delay="200"
            data-aos="fade-up"
          >{`${title}`}</span>
          <span
            className={`purple-title__title purple-title__title_animated`}
            data-aos-delay="200"
            data-aos="fade-up"
          >{`${title}`}</span>
        </>
      </div>
    </div>
  );
};

export default WithDeviceType(PurpleTitle);
