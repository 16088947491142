import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

const AccordionButton = ({ stage, title }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="accordion__button" onClick={() => setOpen((prev) => !prev)}>
      <span>{ReactHtmlParser(stage)}</span>
      <span>{ReactHtmlParser(title)}</span>
      <div className={`accordion__icon ${open ? 'accordion__icon_active' : ''}`}>
        <div className="accordion__icon_line"></div>
        <div className="accordion__icon_line"></div>
      </div>
    </div>
  );
};

export default AccordionButton;
