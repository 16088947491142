import React, { useEffect, useRef, useState } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { teamAPI } from '../../../../api';
import { reinitSwiper } from '../../../functions/functions';
import WithDeviceType from '../../../HOCs/WithDeviceType';

const AgencyTeam = ({ mainRef, desktop }) => {
  let [members, setMembers] = useState([]);
  let slider = useRef(null).current;
  let sliderProgress = 0;
  let dragBtn = useRef(null);
  let onThumbMousedown;
  let thumbCoord;

  useEffect(() => {
    let btn = mainRef.current.querySelector('.agency-team__slider-btn');
    if (slider) {
      setTimeout(() => {
        initSlider(slider, btn);
        document.addEventListener('mousedown', onThumbMousedown);
      }, 1000);
    }
  }, [slider]);

  useEffect(() => {
    teamAPI.getTeam().then((res) => setMembers(res));

    return () => {
      document.removeEventListener('mousedown', onThumbMousedown);
    };
  }, []);

  SwiperCore.use([Navigation]);

  const initNav = (swiper) => {
    swiper.navigation.prevEl.innerHTML = `<svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7618 39L-6.45189e-06 19.5L19.7618 2.30769e-06L23.7142 3.9L10.6995 16.7423L37 17.0098L37 22.5252L10.6995 22.2577L23.7142 35.1L19.7618 39Z" fill="url(#paint0_radial)"/>
    <defs>
        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.6814 39) rotate(-132.095) scale(50.2437 72.0712)">
            <stop offset="0.0272715" stop-color="#FA3D33"/>
            <stop offset="1" stop-color="#EE8B52"/>
        </radialGradient>
    </defs>
</svg>`;

    swiper.navigation.nextEl.innerHTML = `<svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.2382 3.01402e-06L37 19.5L17.2382 39L13.2858 35.1L26.3005 22.2577L-7.55217e-06 21.9902L-5.65799e-06 16.4748L26.3005 16.7423L13.2858 3.9L17.2382 3.01402e-06Z" fill="url(#paint0_radial)"/>
                                                <defs>
                                                <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.31856) rotate(47.9051) scale(50.2437 72.0712)">
                                                <stop offset="0.0272715" stop-color="#FA3D33"/>
                                                <stop offset="1" stop-color="#EE8B52"/>
                                                </radialGradient>
                                                </defs>
                                                </svg>
                                                `;
  };

  const onSliderStartDrag = () => {
    // dragBtn.current.classList.remove('fadeIn');
    // dragBtn.current.classList.add('fadeOut');
  };

  const onEndSliderDrag = () => {
    if (
      parseFloat(dragBtn.current.style.left) >= 0 ||
      parseFloat(dragBtn.current.style.left) <= 100
    ) {
      // dragBtn.current.style.left = `${sliderProgress * 100}%`;
      dragBtn.current.style.left = thumbCoord + 'px';
      // dragBtn.current.classList.remove('fadeOut');
      // dragBtn.current.classList.add('fadeIn');
    }
  };

  const initSlider = (slider, thumb) => {
    const moveThumb = function (e) {
      let THUMB_WIDTH = thumb.offsetWidth;

      let LimitMovementX = {
        min: slider.wrapperEl.offsetLeft,
        max: slider.wrapperEl.offsetLeft + slider.wrapperEl.offsetWidth - THUMB_WIDTH + 2
      };

      thumbCoord = thumb.offsetLeft + e.movementX;

      if (thumbCoord < LimitMovementX.min) {
        thumbCoord = LimitMovementX.min;
      } else if (thumbCoord > LimitMovementX.max) {
        thumbCoord = LimitMovementX.max;
      }

      thumb.style.left = thumbCoord + 'px';
    };

    onThumbMousedown = function (e) {
      if (
        !e.target.classList.contains('agency-team__slide') &&
        !e.target.classList.contains('agency-team__slide-pic') &&
        !e.target.classList.contains('agency-team__slide-name') &&
        !e.target.classList.contains('agency-team__slide-position')
      ) {
        const onDocumentMousemove = function (e) {
          let offset = `${
            e.clientX -
            (parseFloat(window.getComputedStyle(dragBtn.current).width) +
              parseFloat(window.getComputedStyle(dragBtn.current).width) / 2)
          }`;
          let coef =
            (slider.virtualSize - slider.wrapperEl.offsetWidth) / slider.wrapperEl.offsetWidth;
          if (offset <= 0) {
            mainRef.current.querySelector(
              '.agency-team__slider .swiper-wrapper'
            ).style.transform = `translate3d(0px, 0px, 0px)`;
          } else {
            mainRef.current.querySelector(
              '.agency-team__slider .swiper-wrapper'
            ).style.transform = `translate3d(-${offset * coef}px, 0px, 0px)`;
          }

          moveThumb(e);
        };

        const onThumbMouseup = function () {
          document.removeEventListener('mousemove', onDocumentMousemove);
          document.removeEventListener('mouseup', onThumbMouseup);
        };

        document.addEventListener('mousemove', onDocumentMousemove);
        document.addEventListener('mouseup', onThumbMouseup);
      }
    };
  };

  return (
    <section className="agency-team">
      <div className="wrapper">
        <div className="agency-team__slider">
          <Swiper
            data-aos="fade-in"
            data-aos-delay="300"
            spaceBetween={25}
            freeMode={desktop && true}
            navigation
            lazy={true}
            breakpoints={{
              0: {
                slidesPerView: 'auto',
                spaceBetween: 0
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 25
              },
              1700: {
                slidesPerView: 6
              }
            }}
            onTransitionStart={() => desktop && onSliderStartDrag()}
            onTouchEnd={(swiper) => {
              desktop && onEndSliderDrag();
              sliderProgress = swiper.progress;
            }}
            onTouchStart={() => desktop && onSliderStartDrag()}
            onTransitionEnd={() => desktop && onEndSliderDrag()}
            onSwiper={(swiper) => {
              reinitSwiper(swiper);
              slider = swiper;
              console.log(slider.progress)
              !desktop && initNav(swiper);
            }}
          >
            {members.map((item, i) => {
              return (
                <SwiperSlide
                  key={`${i}-${item.id}`}
                  className="agency-team__slide"
                  //   data-aos="fade-up"
                  //   data-aos-delay={(i + 1) * 100}
                >
                  <div className="agency-team__slide-container">
                    <img src={item.pic} alt={item.name} className="agency-team__slide-pic" />
                  </div>
                  <span className="agency-team__slide-name">{item.name}</span>
                  <span className="agency-team__slide-position">{item.position}</span>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        {desktop && (
          <div className="agency-team__drag">
            <div className="agency-team__drag-area" />
            <button
              type="button"
              className="agency-team__slider-btn"
              ref={dragBtn}
              style={{ left: 0 }}
            />
          </div>
        )}

        {/*<div className="agency-team__slider-box">*/}
        {/*    <div className="agency-team__slider-wrapper">*/}
        {/*        {windowWidth > 670 ? members.map((item, i) => {*/}
        {/*                if (i > 1) {*/}
        {/*                    return <div key={i} className="agency-team__slide">*/}
        {/*                        <div className="agency-team__slide-container">*/}
        {/*                            <img src={item.pic} alt={item.name} className="agency-team__slide-pic"/>*/}
        {/*                        </div>*/}

        {/*                        <span className="agency-team__slide-name">{item.name}</span>*/}
        {/*                        <span className="agency-team__slide-position">{item.position}</span>*/}
        {/*                    </div>*/}
        {/*                }*/}
        {/*                return false;*/}
        {/*            })*/}
        {/*            : <Swiper*/}
        {/*                data-aos="fade-in"*/}
        {/*                data-aos-delay="300"*/}
        {/*                spaceBetween={20}*/}
        {/*                slidesPerView={windowWidth < 560 ? 2 : 1.8}*/}
        {/*                onSwiper={(swiper) => {*/}
        {/*                    reinitSwiper(swiper)*/}
        {/*                }}*/}
        {/*            >*/}
        {/*                {members.map((item, i) => {*/}
        {/*                    return <SwiperSlide key={i} className="agency-team__slide">*/}
        {/*                        <div className="agency-team__slide-container">*/}
        {/*                            <img src={item.pic} alt={item.name} className="agency-team__slide-pic"/>*/}
        {/*                        </div>*/}
        {/*                        <span className="agency-team__slide-name">{item.name}</span>*/}
        {/*                        <span className="agency-team__slide-position">{item.position}</span>*/}
        {/*                    </SwiperSlide>*/}
        {/*                })}*/}
        {/*            </Swiper>*/}
        {/*        }*/}

        {/*    </div>*/}
        {/*    <div className="agency-team__drag-area"  onClick={onClick}/>*/}
        {/*    <button type="button" className="agency-team__slider-btn"/>*/}
        {/*</div>*/}
      </div>
    </section>
  );
};

export default WithDeviceType(AgencyTeam);
