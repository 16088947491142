import React, { useEffect } from 'react';
import ContactsSection from '../MainPage/MainPageSections/ContactsSection';
import WithDeviceType from '../../HOCs/WithDeviceType';
import 'swiper/swiper.scss';
import Helmet from 'react-helmet';
import { useAppContext } from '../../../AppContext';
import WithTransition from '../../HOCs/WithTransition';
import '../MainPage/MainPage.scss';
import { NavLink } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import TextSection from '../MainPage/MainPageSections/TextSection';
import SocialSection from '../MainPage/MainPageSections/SocialSection';
import HistorySection from '../MainPage/MainPageSections/HistorySection';
import BlackButton from '../../Common/BlackButton/BlackButton';
import VakancySection from '../MainPage/MainPageSections/VakancySection';
import AsapLive from '../MainPage/MainPageSections/AsapLive';
import TitleSection from '../../TitleSection/TitleSection';
import './OtraslExpertiza.scss';

const OtraslExpertiza = ({ isIndexPage, setIsIndexPage }) => {

    const { initPage, mainRef } = useAppContext();

    useEffect(() => {
        initPage();
    }, []);

    return (
        <div className='expertise'>
            <Helmet>
                <title>Отраслевая экспертиза</title>
                <meta
                    name="description"
                    content="Digital-решения для амбициозных компаний. Разработка сайтов и цифровых продуктов. Комплексный интернет-маркетинг."
                />

            </Helmet>
            <TitleSection textTitle="Отраслевая экспертиза" textContent='Проектные команды и менеджеры со специализацией в отраслях бизнеса.'>
                
            </TitleSection>
            <HistorySection />
            <TextSection>
                Формирование идеи, изучение рынка, анализ конкурентов, создание MVP, пивоты, поиск product market fit, масштабирование продукта - всё это мы регулярно проходим на собственных проектах.
            </TextSection>
            <SocialSection
                titleText="Цитата"
                personText="Для наших клиентов мы являемся технологическими партнерами с отраслевой экспертизой, а не просто подрядчиками."
            >
                <NavLink
                    to={`/brief`}
                    onClick={() => {
                        ReactGA.event({
                            category: 'appayer_sur_le_bouton',
                            action: 'aller_sur_une_autre_page',
                            label: 'aller_sur_une_autre_page_toit_de_site'
                        });
                        ym('reachGoal', 'startproject');
                    }}
                    className=""
                >
                    <BlackButton>Запустим продукт вместе</BlackButton>
                </NavLink>
            </SocialSection>
            <VakancySection />
            <AsapLive />
            <ContactsSection />
        </div>
    )
}

export default WithTransition(WithDeviceType(OtraslExpertiza))