import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import WithTransition from '../../HOCs/WithTransition';
import WithDeviceType from '../../HOCs/WithDeviceType';
import { useAppContext } from '../../../AppContext';
import { lavaLamp } from '../../functions/functions';

import AppSuccess from '../../PromotionBlocks/AppSuccess';
import footerImg from '../../../assets/images/footer.png';

const AppSuccessPage = ({ isIndexPage, setIsIndexPage }) => {
  const { initPage, mainRef } = useAppContext();

  useEffect(() => {
    setIsIndexPage(true);

    return () => {
      setIsIndexPage(false);
    };
  }, [isIndexPage]);

  useEffect(() => {
    initPage();
  }, []);

  return (
    <div className='success-page'>
      <Helmet>
        <title>ASAP. Разработка программного обеспечения и сложных сайтов</title>
        <meta
          name="description"
          content="Digital-решения для амбициозных компаний. Разработка сайтов и цифровых продуктов. Комплексный интернет-маркетинг."
        />
      </Helmet>
      <img className='success-bg' src={footerImg} alt="" />
      <AppSuccess />
    </div>
  );
};

export default WithTransition(WithDeviceType(AppSuccessPage));
