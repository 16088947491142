import React, { useEffect, useState } from 'react';
import 'swiper/swiper.scss';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperCore, { Navigation, EffectFade, Autoplay, Thumbs } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/effect-fade/effect-fade.min.css';
import './FactsSlider.scss';

const FactsSlider = () => {
    const [progress, setProgress] = useState(0);

    SwiperCore.use([EffectFade, Navigation, Autoplay]);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => (prev < 100 ? prev + 1 : 0));
        }, 55); 

        return () => clearInterval(interval);
    }, []);
    return (
        <div className="swiper-container">
            <div className="progress-bar">
                <div
                    className="progress-bar__fill"
                    style={{ width: `${progress}%` }}
                ></div>
            </div>
            <Swiper
                slidesPerView={1}
                spaceBetween={25}
                navigation={false}
                autoplay={{ delay: 5000 }}
                loop
                effect="fade"
                onSlideChange={() => setProgress(0)}
                className="razrabotka-slider"
            >
                <SwiperSlide>
                    <p className="h3">Топ-10</p>
                    <p className="razrabotka-slider__text">
                        Лучших разработчиков корпоративных программ/сервисов «под ключ»
                    </p>
                </SwiperSlide>
                <SwiperSlide>
                    <p className="h3">Топ-20</p>
                    <p className="razrabotka-slider__text">
                        Лучших разработчиков интернет-магазинов
                    </p>
                </SwiperSlide>
                <SwiperSlide>
                    <p className="h3">Топ-100</p>
                    <p className="razrabotka-slider__text">
                        Лучших разработчиков сайтов «под ключ»
                    </p>
                </SwiperSlide>
                <SwiperSlide>
                    <p className="h3">Топ-100</p>
                    <p className="razrabotka-slider__text">
                        Лучших подрядчиков иностранных компаний
                    </p>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default FactsSlider