import React, {useState, useEffect} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {baseURL, partners} from '../../../../constants';
import {teamAPI} from '../../../../api';
import './scss/СonsultationSection.scss';
import Consultation from '../../ConsultationPage/ConsultationPage'

const СonsultationSection = () => {
    const [members, setMembers] = useState([]);

    useEffect(() => {
        teamAPI.getTeam().then((res) => setMembers(res));
    }, []);

    return (
        <section className="consul">
            <div className="wrapper consul-wrapper">
                <div className="consul-header">
                    <h3 className="consul-header__title" data-aos="fade-up">
                        Консультация от&nbsp;IT-эксперта
                    </h3>
                    <p className="consul-header__text" data-aos="fade-up">
                        В течение 30-ти минут вы получите предварительные варианты решения вашей задачи с&nbsp;помощью IT-продуктов. Консультации ведут менеджеры проектов и&nbsp;руководители с&nbsp;опытом работы более 5&nbsp;лет.
                    </p>
                </div>
                <div className="consul-main" data-aos="fade-up">
                    <div className="consul-main__item">
                        <img
                            src={require('../../../../assets/images/consultation/1.png')}
                            alt={'Данил'}
                            className="consul-main__pic"
                        />
                    </div>
                    <div className="consul-main__item">
                        <img
                            src={require('../../../../assets/images/consultation/2.png')}
                            alt={'Данил'}
                            className="consul-main__pic"
                        />
                    </div>
                    <div className="consul-main__item">
                        <img
                            src={require('../../../../assets/images/consultation/3.png')}
                            alt={'Данил'}
                            className="consul-main__pic"
                        />
                    </div>
                </div>
                <Link to='/consultation' className="consul__btn" data-aos="fade-up">
                    Получить консультацию
                </Link>
            </div>
        </section>
    );
};

export default СonsultationSection;
