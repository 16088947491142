import React, { useEffect, useState } from 'react';
import WithDeviceType from '../../../HOCs/WithDeviceType';
import { LayoutBlock, LayoutWrapper } from '../../../Layout';

const AgencyApproach = ({ desktop }) => {
  let [approaches, setApproaches] = useState([]);

  let initialApproaches = [
    {
      title: 'Аналитика и исследования',
      content: ''
    },
    {
      title: 'Формирование бэклога гипотез',
      content: ''
    },
    {
      title: 'Проработка и приоритизация',
      content: ''
    },
    {
      title: 'Удобный и креативный дизайн',
      content: ''
    },
    {
      title: 'Разработка по стандартам отрасли',
      content: ''
    },
    {
      title: 'Тестирование',
      content: ''
    },
    {
      title: 'Контроль метрик, формирование выводов',
      content: ''
    },
  ];

  useEffect(() => {
    setApproaches(initialApproaches);
  }, []);

  return (
    <section className="approach">
      <LayoutWrapper>
        <div className="approach__box">
          <LayoutBlock className="approach__title">
            <h2 className="approach__title title aos-init aos-animate" data-aos="fade-up">
              Наш подход
            </h2>
          </LayoutBlock>
          <LayoutBlock className="approach__info">
            <p className="approach__info-text" data-aos="fade-up">
              Фокусируемся на&nbsp;бизнес-целях клиента. Генерируем гипотезы для&nbsp;роста показателей бизнеса через IT-решение.
              <br />
              <br />
              Для генерации продуктовых гипотез и&nbsp;создания оптимальных пользовательских сценариев мы&nbsp;анализируем конкурентов, изучаем потребности целевой аудитории, следим за&nbsp;метриками.
            </p>
            <ul className="approach__list">
              {approaches.map((item, i) => {
                return (
                  <li
                    key={i}
                    className="approach__item"
                    data-aos="fade-up"
                    data-aos-delay={(i + 1) * 50}
                  >
                    <span className="approach__item-title">{item.title}</span>
                    <span className="approach__item-num">{i + 1 < 10 ? `0${i + 1}` : i + 1}</span>
                  </li>
                );
              })}
            </ul>
            <p className="approach__text" data-aos="fade-up">
              Мы&nbsp;стремимся каждый проект сделать лучшим в&nbsp;нише.
              <br />
              Никогда не&nbsp;жертвуем качеством ради экономии в&nbsp;себестоимости. Поэтому
              к&nbsp;нам обращаются лидеры рынка и&nbsp;амбициозные стартапы.
            </p>
          </LayoutBlock>
        </div>

        <div className="approach__box approach__box--mg">
          <LayoutBlock className="approach__title">
            <h2 className="approach__title title aos-init aos-animate" data-aos="fade-up">
              Delivery
            </h2>
          </LayoutBlock>
          <LayoutBlock className="approach__info">
            <p className="approach__info-text" data-aos="fade-up">
              Доставка продуктовой ценности от&nbsp;идеи до&nbsp;релиза, или&nbsp;этап непосредственной реализации продукта.
              <br />
              Задача этого этапа - довести функции, описанные на&nbsp;этапе discovery, до&nbsp;продакшена - за&nbsp;минимальный срок и&nbsp;с&nbsp;максимальным качеством.
            </p>
          </LayoutBlock>
        </div>

        <div className="approach__box">
          <LayoutBlock className="approach__title">
            <h2 className="approach__title title aos-init aos-animate" data-aos="fade-up">
              Discovery
            </h2>
          </LayoutBlock>
          <LayoutBlock className="approach__info">
            <p className="approach__info-text" data-aos="fade-up">
              Задача этапа product discovery - генерировать гипотезы для&nbsp;роста показателей бизнеса через IT-решение.
              <br />
              Проще говоря, discovery отвечает на&nbsp;вопрос: "Как&nbsp;мы&nbsp;можем помочь клиенту увеличить прибыль?"
            </p>
          </LayoutBlock>
        </div>
      </LayoutWrapper>
    </section>
  );
};

export default WithDeviceType(AgencyApproach);
