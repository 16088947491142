import React from 'react';
import { closeBtn, errorIcon } from '../../constants';
import './scss/Alert.scss';

const Alert = ({ title, text, buttonText, onClose, isErrorAlert = false }) => {
  return (
    <div className="alert">
      <div className="alert__content">
        <button className="alert__close" onClick={onClose}>
          {closeBtn}
        </button>
        {isErrorAlert ? (
          <div className="alert__icon">{errorIcon}</div>
        ) : (
          <span className="alert__title">{title}</span>
        )}
        <p className="alert__text">{text}</p>
        <button className="alert__btn" onClick={onClose}>
          <span className='label-text'>{buttonText}</span>
        </button>
      </div>
    </div>
  );
};

export default Alert;
