import axios from 'axios';
import { baseURL } from './constants';

const instanceJson = axios.create({
  baseURL: '/data',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

export const awardsAPi = {
  async getAwards() {
    return (await instance.get('awards')).data;
  }
};

export const projectsAPI = {
  async getMainProjects() {
    return (await instance.get('projects/main')).data;
  },

  async setProjectId(id) {
    return (await instance.get(`projects/slider?currentProjectId=${id}`)).data;
  },


  async getProjectsToSlider() {
    return (await instance.get('projects/slider')).data;
  },

  async getPreviewProjects(typeID) {
    const params = typeID
      ? {
          params: {
            typeId: typeID
          }
        }
      : null;
    return (await instance.get('projects/preview', params)).data;
  },

  async getCategories() {
    return (await instance.get('projects/types')).data;
  },

  async getProjects(page, typeID) {
    const params = page
      ? {
          params: {
            page,
            typeId: typeID
          }
        }
      : null;
    return (await instance.get('projects/list', params)).data;
  },

  async getProject(link) {
    return (await instance.get(`projects/${link}`)).data;
  }
};

export const servicesAPI = {
  async getServicesTitles() {
    return (await instance.get('services/onlyTitle')).data;
  },

  async getServices() {
    return (await instance.get('services')).data;
  }
};

export const teamAPI = {
  async getTeam() {
    return (await instance.get('teams')).data;
  }
};

export const articlesAPI = {
  async getArticles(limit = 6, page = 1) {
    return (
      await instance.get(`blog`, {
        params: {
          limit,
          page
        }
      })
    ).data;
  }
};

export const briefAPI = {
  async sendBrief(data) {
    return (
      await instance.post(`brief`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    ).data;
  }
};

export const promoAPI = {
  async sendAudit(data) {
    await axios.get('https://asap-ag.ru/sanctum/csrf-cookie', { withCredentials: true });
    return (
      await instance.post(
        `mail`,
        data,
        { headers: { 'Content-Type': 'multipart/form-data' } },
        { withCredentials: true }
      )
    ).data;
  }
};

export class promoDataAPI {
  constructor(data) {
    this.data = data;
  }
  async sendInfo(paragraph) {
    return (await instanceJson.get(this.data)).data[paragraph];
  }
}
