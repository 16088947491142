import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import WithDeviceType from '../../HOCs/WithDeviceType';
import './index.scss';
import Collapsible from 'react-collapsible';
import AccordionButton from './Button';

const Accordions = ({ api, style }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    api.sendInfo('develop').then((res) => setData(res));
  }, []);

  return (
    <section className="accordionSolutions" style={style}>
      <div className="wrapper">
        <h1 className="accordionSolutions__title">
          Мы выстроили <span className="purple">поэтапный процесс разработки</span>, покрывающий все
          аспекты e-commerce продукта
        </h1>
        {data?.map((item, index) => (
          <Collapsible
            key={`accordion--${index}`}
            trigger={<AccordionButton stage={item.stage} title={item.title} />}
            className="accordion"
            openedClassName="accordion activeAccordion"
          >
            <div className="accordion__content">
              <p>{item.text}</p>
            </div>
          </Collapsible>
        ))}
      </div>
    </section>
  );
};

export default WithDeviceType(Accordions);
